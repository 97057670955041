import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { joinClasses } from '../../utils/strings'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontSize: theme.typography.overline.fontSize,
      color: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
    },
  }),
)

export type BasicLabelProps = {
  className?: string
  label: string
}

const BasicLabel = ({ className, label }: BasicLabelProps) => {
  const classes = useStyles()
  return (
    <span className={joinClasses(classes.container, className)}>{label}</span>
  )
}

export default BasicLabel
