import { Branch } from '../interfaces/branch'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BranchesData } from '../apis/setting'
import { ImportErrorResponse, Pagination } from '../interfaces/api'

interface State {
  branches: Branch[] | null
  branch: Branch | null
  paginationBranches: Pagination | null
  waitingBranches: boolean
  waitingBranch: boolean
  waitingUploadCsv: boolean
  waitingDownloadCsv: boolean
  errorResponseResults: ImportErrorResponse[] | null
}

const initialState: State = {
  branches: null,
  branch: null,
  paginationBranches: null,
  waitingBranches: false,
  waitingBranch: false,
  waitingUploadCsv: false,
  waitingDownloadCsv: false,
  errorResponseResults: null,
}

const branch = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    setWaitingBranches: (state): void => {
      state.waitingBranches = true
    },
    setWaitingBranch: (state): void => {
      state.waitingBranch = true
    },
    setBranches: (state, actions: PayloadAction<BranchesData>): void => {
      state.branches = actions.payload.branches
      state.waitingBranches = initialState.waitingBranches
    },
    setBranch: (state, actions: PayloadAction<Branch>): void => {
      state.branch = actions.payload
      state.waitingBranch = false
    },
    setPaginationBranches: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationBranches = actions.payload.pagination
    },
    resetBranches: (state) => {
      state.branches = initialState.branches
      state.paginationBranches = initialState.paginationBranches
    },
    resetBranch: (state) => {
      state.branch = initialState.branch
    },
    setWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = true
    },
    setWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = true
    },
    resetWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = initialState.waitingUploadCsv
    },
    resetWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = initialState.waitingDownloadCsv
    },
    setErrorResponseResults: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseResults = actions.payload.errors
    },
    resetErrorResponseResults: (state): void => {
      state.errorResponseResults = initialState.errorResponseResults
    },
  },
})

export const actions = branch.actions
export default branch.reducer
