import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { joinClasses } from '@/utils/strings'
import { ErrorOutline } from '@mui/icons-material'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    icon: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  }),
)

type Props = {
  className?: string
  error: unknown
  componentStack: string | null
  eventId: string | null
  resetError: () => void
}

export const InternalError = ({ className, error }: Props) => {
  const classes = useStyles()
  console.error(error)

  return (
    <div className={joinClasses(classes.container, className)}>
      <ErrorOutline className={classes.icon} />
      <Typography component="h2" variant="h4">
        内部エラーが発生しました
      </Typography>
    </div>
  )
}
