import { AppDispatch, store } from '../../store'
import { actions } from '../../modules/setting'
import { getEmployeeGenders } from '../../apis/setting'
import { checkResponseDataStatus } from '../api'
import { enqueueErrorSnackbar } from '../snackbars'

export const fetchEmployeeGenders =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    const employeeGenders = store.getState().setting.employeeGenders
    if (employeeGenders?.length) {
      return Promise.resolve()
    }

    dispatch(actions.setWaitingEmployeeGenders())
    return getEmployeeGenders()
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setEmployeeGenders(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingEmployeeGenders())
      })
  }

export const resetEmployeeGenders =
  () =>
  (dispatch: AppDispatch): { type: string } =>
    dispatch(actions.resetEmployeeGenders())
