import { api, apiVersion } from '../base'
import { ApiDataResponse } from '../../interfaces/api'
import { EmployeeTitle } from '../../interfaces/employee'

export type EmployeeTitlesData = {
  employeeTitles: EmployeeTitle[]
}

type EmployeeTitlesResponse = ApiDataResponse<EmployeeTitlesData>

export const getEmployeeTitles = (): Promise<EmployeeTitlesResponse> =>
  api.get(`${apiVersion}/settings/employees/title`)
