import { SelectMap } from '../../interfaces/utils'

/** 健康診断種別 */
export const checkupType = {
  /** 定期健康診断 */
  REGULAR: 1,
  /** 雇用時健診 */
  BEGINNING: 2,
  /** 特定業務健診 */
  PARTICULAR: 3,
  /** 海外派遣健診 */
  FOREIGN: 4,
} as const

/** 健康診断種別ラベルマップ */
export const checkupTypeMap: SelectMap[] = [
  { value: checkupType.REGULAR, label: '定期健康診断' },
  { value: checkupType.BEGINNING, label: '雇用時健診' },
  { value: checkupType.PARTICULAR, label: '特定業務健診' },
  { value: checkupType.FOREIGN, label: '海外派遣健診' },
]

/** 健診ステータス */
export const checkupEventStatus = {
  /** 未受診 */
  NOT_CHECKED: 0,
  /** 受診済 */
  CHECKED: 1,
} as const

/** 健診ステータスマップ */
export const checkupEventStatusMap: SelectMap[] = [
  { value: checkupEventStatus.NOT_CHECKED, label: '未受診' },
  { value: checkupEventStatus.CHECKED, label: '受診済' },
]

/** 医療判定 */
export const checkupMedicalDecide = {
  /** 未判定 */
  NOT_DECIDE: 0,
  /** 異常なし */
  NOTHING: 1,
  /** 要面談 */
  INTERVIEW: 2,
  /** 経過観察 */
  FOLLOW_UP: 3,
  /** 要医療 */
  MEDICAL: 4,
} as const

/** 医療判定マップ */
export const checkupMedicalDecideMap: SelectMap[] = [
  { value: checkupMedicalDecide.NOT_DECIDE, label: '未判定' },
  { value: checkupMedicalDecide.NOTHING, label: '異常なし' },
  { value: checkupMedicalDecide.INTERVIEW, label: '要面談' },
  { value: checkupMedicalDecide.FOLLOW_UP, label: '経過観察' },
  { value: checkupMedicalDecide.MEDICAL, label: '要医療' },
]

/** 就業判定 */
export const checkupWorkingDecide = {
  /** 未判定 */
  NOT_DECIDE: 0,
  /** 就業制限なし */
  NO_RESTRICT: 1,
  /** 就業制限あり */
  RESTRICT: 2,
  /** 就業制限要検討 */
  CONSIDER_RESTRICT: 3,
  /** 海外渡航不可 */
  TRAVEL_RESTRICT: 4,
  /** 海外渡航要検討 */
  CONSIDER_TRAVEL_RESTRICT: 5,
  /** 判定保留 */
  PENDING: 6,
} as const

/** 就業判定マップ */
export const checkupWorkingDecideMap: SelectMap[] = [
  { value: checkupWorkingDecide.NOT_DECIDE, label: '未判定' },
  { value: checkupWorkingDecide.NO_RESTRICT, label: '就業制限なし' },
  { value: checkupWorkingDecide.RESTRICT, label: '就業制限あり' },
  { value: checkupWorkingDecide.CONSIDER_RESTRICT, label: '就業制限要検討' },
  { value: checkupWorkingDecide.TRAVEL_RESTRICT, label: '海外渡航不可' },
  {
    value: checkupWorkingDecide.CONSIDER_TRAVEL_RESTRICT,
    label: '海外渡航要検討',
  },
  { value: checkupWorkingDecide.PENDING, label: '判定保留' },
]

/** 面談 */
export const checkupInterview = {
  /** 不要 */
  NOT_NEEDED: 0,
  /** 要 */
  NEEDED: 1,
} as const

/** 面談マップ */
export const checkupInterviewMap: SelectMap[] = [
  { value: checkupInterview.NOT_NEEDED, label: '不要' },
  { value: checkupInterview.NEEDED, label: '要' },
]

/** 事後措置 */
export const checkupAfterMeasure = {
  /** 対象外 */
  NOT_APPLICABLE: 0,
  /** 対象 */
  APPLICABLE: 1,
} as const

/** 事後措置マップ */
export const checkupAfterMeasureMap: SelectMap[] = [
  { value: checkupAfterMeasure.NOT_APPLICABLE, label: '対象外' },
  { value: checkupAfterMeasure.APPLICABLE, label: '対象' },
]

/** 事後措置票配信 */
export const checkupResultSendAt = {
  /** 未送信 */
  NOT_SEND: 0,
  /** 送信済 */
  SENT: 1,
} as const

/** 事後措置票配信マップ */
export const checkupResultSendAtMap: SelectMap[] = [
  { value: checkupResultSendAt.NOT_SEND, label: '未送信' },
  { value: checkupResultSendAt.SENT, label: '送信済' },
]

/** 事後措置受診報告 */
export const checkupResultRepliedAt = {
  /** 未報告 */
  NOT_REPLY: 0,
  /** 報告済 */
  REPLIED: 1,
} as const

/** 事後措置受診報告マップ */
export const checkupResultRepliedAtMap: SelectMap[] = [
  { value: checkupResultRepliedAt.NOT_REPLY, label: '未報告' },
  { value: checkupResultRepliedAt.REPLIED, label: '報告済' },
]

/** 最終判定 */
export const checkupFinalDecide = {
  /** 未判定 */
  NOT_DECIDE: 0,
  /** 就業可能 */
  POSSIBLE: 1,
  /** 就業制限 */
  RESTRICT: 2,
  /** 就業不可 */
  IMPOSSIBLE: 3,
  /** 判定保留 */
  PENDING: 4,
} as const

/** 最終判定マップ */
export const checkupFinalDecideMap: SelectMap[] = [
  { value: checkupFinalDecide.NOT_DECIDE, label: '未判定' },
  { value: checkupFinalDecide.POSSIBLE, label: '就業可能' },
  { value: checkupFinalDecide.RESTRICT, label: '就業制限' },
  { value: checkupFinalDecide.IMPOSSIBLE, label: '就業不可' },
  { value: checkupFinalDecide.PENDING, label: '判定保留' },
]
