// keyで探してなかったらnullを返す
const _getItem = (key: string, storage: Storage): Account | null => {
  try {
    const item = storage.getItem(key)
    if (!item) return null
    return JSON.parse(item)
  } catch (e) {
    console.warn('ストレージ情報が壊れています', key)
    return null
  }
}

// 同名のキーが登録にきたら上書きする
const _setItem = (key: string, value: Account, storage: Storage) => {
  try {
    const originItem = _getItem(key, storage)
    const newValue = { ...originItem, ...value }
    storage.setItem(key, JSON.stringify(newValue))
    // storage.setItem(key, newValue)
  } catch (e) {
    console.error(e)
  }
}

// keyで探してなかったらnullを返す
const _getItemToken = (key: string, storage: Storage): Token | null => {
  try {
    const item = storage.getItem(key)
    if (!item) return null
    return JSON.parse(item)
  } catch (e) {
    console.warn('ストレージ情報が壊れています', key)
    return null
  }
}

// 同名のキーが登録にきたら上書きする
const _setItemToken = (key: string, value: Token, storage: Storage) => {
  try {
    const originItem = _getItem(key, storage)
    const newValue = { ...originItem, ...value }
    storage.setItem(key, JSON.stringify(newValue))
    // storage.setItem(key, newValue)
  } catch (e) {
    console.error(e)
  }
}

// keyで探してなかったらnullを返す
const _getCompanyItem = (
  key: string,
  storage: Storage,
): CompanyDetail | null => {
  try {
    const item = storage.getItem(key)
    if (!item) return null
    return JSON.parse(item)
  } catch (e) {
    console.warn('ストレージ情報が壊れています', key)
    return null
  }
}

// 同名のキーが登録にきたら上書きする
const _setCompanyItem = (
  key: string,
  value: CompanyDetail,
  storage: Storage,
) => {
  try {
    const originItem = _getItem(key, storage)
    const newValue = { ...originItem, ...value }
    storage.setItem(key, JSON.stringify(newValue))
    // storage.setItem(key, newValue)
  } catch (e) {
    console.error(e)
  }
}

// keyで探してなかったらnullを返す
const _getMenuItem = (key: string, storage: Storage): MenuGroups | null => {
  try {
    const item = storage.getItem(key)
    if (!item) return null
    return JSON.parse(item)
  } catch (e) {
    console.warn('ストレージ情報が壊れています', key)
    return null
  }
}

// 同名のキーが登録にきたら上書きする
const _setMenuItem = (key: string, value: MenuGroups, storage: Storage) => {
  try {
    const originItem = _getItem(key, storage)
    const newValue = { ...originItem, ...value }
    storage.setItem(key, JSON.stringify(newValue))
    // storage.setItem(key, newValue)
  } catch (e) {
    console.error(e)
  }
}

type Account = {
  companyCode: string
  companyName: string
  id: number
  name: string
  email: string | null
  loginCount: number | null
  otpAuth: number | null
  otpAuthName: string | null
  lastOtpDate: string | null
  otpSecret: string | null
}

type CompanyDetail = {
  code: string
  name: string
  address: string
  tel: string | null
  formatTel: string | null
  startedDate: string | null
  plan?: Plan | null
}

type Plan = {
  id: number
  name: string
  details: PlanDetails[]
}

type PlanDetails = {
  id: number
  menuId: number
  menuName: string
  menuDetailId: number
  menuDetailsName: string
  actived: boolean
}

type Token = {
  accessToken: string
  refreshToken: string
}

export type MenuGroups = {
  menuName?: string
  menuDetailsName?: string
}

export const setUserAccount = (value: Account): void => {
  _setItem(
    'userInfo',
    {
      companyCode: value.companyCode,
      companyName: value.companyName,
      id: value.id,
      name: value.name,
      email: value.email,
      loginCount: value.loginCount,
      otpAuth: value.otpAuth,
      otpAuthName: value.otpAuthName,
      lastOtpDate: value.lastOtpDate,
      otpSecret: value.otpSecret,
    },
    sessionStorage,
  )
}

export const setCompanyAccount = (value: CompanyDetail): void => {
  _setCompanyItem(
    'companyInfo',
    {
      code: value.code,
      name: value.name,
      address: value.address,
      tel: value.tel,
      formatTel: value.formatTel,
      startedDate: value.startedDate,
      plan: value.plan,
    },
    sessionStorage,
  )
}

export const setUserToken = (value: Token): void => {
  _setItemToken(
    'userToken',
    {
      accessToken: value.accessToken,
      refreshToken: value.refreshToken,
    },
    sessionStorage,
  )
}

export const setMenuGroups = (value: MenuGroups): void => {
  _setMenuItem(
    'menuInfo',
    {
      menuName: value.menuName,
      menuDetailsName: value.menuDetailsName,
    },
    sessionStorage,
  )
}

export const getUserInfo = (): Account | null => {
  return _getItem('userInfo', sessionStorage)
}

export const getCompanyInfo = (): CompanyDetail | null => {
  return _getCompanyItem('companyInfo', sessionStorage)
}

export const getMenuInfo = (): MenuGroups | null => {
  return _getMenuItem('menuInfo', sessionStorage)
}

const getUserToken = (): Token | null => {
  return _getItemToken('userToken', sessionStorage)
}

export const getUserId = (): number | null => {
  try {
    const userInfo = getUserInfo()
    return userInfo?.id ? userInfo.id : null
  } catch (e) {
    return null
  }
}

export const getAccessToken = (): string | null => {
  try {
    const userToken = _getItemToken('userToken', sessionStorage)
    return userToken?.accessToken ? userToken.accessToken : null
  } catch (e) {
    return null
  }
}

export const isLogin = (): boolean => {
  try {
    const userToken = getUserToken()
    return !!userToken?.accessToken
  } catch (e) {
    return false
  }
}

// TODO 他のサイトも巻き込んで全消しなのであとで調整する
export const removeAll = (): void => {
  if (sessionStorage) {
    sessionStorage.clear()
  }
  if (localStorage) {
    localStorage.clear()
  }
}
