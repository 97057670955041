import {
  PersonnelReport,
  PersonnelReportDetail,
} from '../../interfaces/shareNavi/personnelReport'
import { Pagination } from '../../interfaces/api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  PersonnelReportData,
  PersonnelReportsData,
} from '../../apis/shareNavi/personnelReport'

interface State {
  personnelReports: PersonnelReport[] | null
  personnelReport: PersonnelReportDetail | null

  paginationPersonnelReports: Pagination | null

  waitingPersonnelReports: boolean
  waitingPersonnelReport: boolean
}

const initialState: State = {
  personnelReports: null,
  personnelReport: null,

  paginationPersonnelReports: null,

  waitingPersonnelReports: false,
  waitingPersonnelReport: false,
}

const personnelReport = createSlice({
  name: 'personnelReport',
  initialState,
  reducers: {
    setWaitingPersonnelReports: (state) => {
      state.waitingPersonnelReports = true
    },
    setWaitingPersonnelReport: (state) => {
      state.waitingPersonnelReport = true
    },
    setPersonnelReports: (
      state,
      actions: PayloadAction<PersonnelReportsData>,
    ) => {
      state.personnelReports = actions.payload.personnelReports
    },
    setPersonnelReport: (
      state,
      actions: PayloadAction<PersonnelReportData>,
    ) => {
      state.personnelReport = actions.payload.personnelReport
    },
    setPaginationPersonnelReports: (
      state,
      actions: PayloadAction<Pagination | null>,
    ) => {
      state.paginationPersonnelReports = actions.payload
    },
    resetPersonnelReports: (state) => {
      state.personnelReports = initialState.personnelReports
    },
    resetPersonnelReport: (state) => {
      state.personnelReport = initialState.personnelReport
    },
    resetWaitingPersonnelReports: (state) => {
      state.waitingPersonnelReports = initialState.waitingPersonnelReports
    },
    resetWaitingPersonnelReport: (state) => {
      state.waitingPersonnelReport = initialState.waitingPersonnelReport
    },
  },
})

export const actions = personnelReport.actions
export default personnelReport.reducer
