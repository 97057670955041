export const httpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
}

export const httpMessage = {
  [httpStatus.BAD_REQUEST]: '不正なリクエストです',
  [httpStatus.UNAUTHORIZED]: 'ログイン認証に失敗しました',
  [httpStatus.FORBIDDEN]: 'ログイン認証の有効期限が切れました',
  [httpStatus.NOT_FOUND]: 'データがありません',
  [httpStatus.UNPROCESSABLE_ENTITY]: '処理できませんでした',
  [httpStatus.INTERNAL_SERVER_ERROR]: 'システムエラーが発生しました',
  [httpStatus.BAD_GATEWAY]: 'サーバー接続に問題が発生しました',
}
