import {
  Interview,
  InterviewFile,
  InterviewHistory,
  InterviewReason,
  InterviewReference,
  InterviewTemplate,
  InterviewWay,
} from '../../../interfaces/shareNavi/interview'
import { ApiDataResponse } from '../../../interfaces/api'

export type InterviewsData = Readonly<{
  interviews: Interview[]
}>
export type InterviewData = Readonly<{
  interview: Interview
}>
export type InterviewFileData = Readonly<{
  interviewFile: InterviewFile
}>
export type InterviewTemplatesData = Readonly<{
  templates: InterviewTemplate[]
}>
export type InterviewHistoriesData = Readonly<{
  interviewHistories: InterviewHistory[]
}>
export type InterviewReasonsData = Readonly<{
  reasons: InterviewReason[]
}>
export type InterviewWaysData = Readonly<{
  ways: InterviewWay[]
}>
export type InterviewReferencesData = Readonly<{
  references: InterviewReference[]
}>

export type InterviewsResponse = ApiDataResponse<InterviewsData>
export type InterviewResponse = ApiDataResponse<InterviewData>
export type InterviewFileResponse = ApiDataResponse<InterviewFileData>
export type InterviewTemplatesResponse = ApiDataResponse<InterviewTemplatesData>
export type InterviewHistoriesResponse = ApiDataResponse<InterviewHistoriesData>
export type InterviewReasonsResponse = ApiDataResponse<InterviewReasonsData>
export type InterviewWaysResponse = ApiDataResponse<InterviewWaysData>
export type InterviewReferencesResponse =
  ApiDataResponse<InterviewReferencesData>

export type InterviewsQuery = {
  /** 面談ステータスID配列 */
  interviewStatusIds?: number[]
  /** 面談理由ID配列 */
  interviewReasonIds?: number[]
  /** 参照項目ID配列 */
  interviewReferenceIds?: number[]
  /** 面談方法ID配列 */
  interviewWayIds?: number[]
  /** 事業所ID配列 */
  branchIds?: number[]
  /** 部署ID配列 */
  departmentIds?: number[]
  /** 従業員コード・従業員名 */
  name?: string | null
  /** 面談日(YYYY-MM-DD) */
  interviewStartAt?: string | null
  /** 添付有無 */
  hasFile?: number
  /** 面談者ID */
  interviewerId?: number
  /** ページ */
  page?: number
}

export type InterviewParams = Pick<
  Interview,
  | 'startAt'
  | 'endAt'
  | 'stressIndicators'
  | 'memo'
  | 'interviewContact'
  | 'records'
  | 'status'
  | 'interviewRole'
> & {
  /** 対象従業員ID */
  employeeId: number
  /** 面談理由ID */
  interviewReasonId: number
  /** 面談方法ID(任意) */
  interviewWayId?: number
  /** 参照項目(任意) */
  interviewReferenceId?: number
  /** 面談者ID */
  interviewerId: number
  /** 操作ID */
  operation: number
  /** ストレスレベル配列 */
  stressIndicatorsArray?: string[]
}

export type InterviewSendMailParams = Pick<Interview, 'id'> & {
  target: string | undefined
  interviewer: string | undefined
  others: string
}
