import { UserRole } from '../interfaces/userRole'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserRolesData } from '../apis/userRole'
import { User } from '../interfaces/user'

interface State {
  user: User | null
  userRoles: UserRole[] | null
  topPage: string | null
}

const initialState: State = {
  user: null,
  userRoles: null,
  topPage: null,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, actions: PayloadAction<User>) => {
      state.user = actions.payload
    },
    setUserRoles: (state, actions: PayloadAction<UserRolesData>) => {
      state.userRoles = actions.payload.userRoles
    },
    setTopPage: (state, actions: PayloadAction<string>) => {
      state.topPage = actions.payload
    },
    reset: (state) => {
      state.user = initialState.user
      state.userRoles = initialState.userRoles
      state.topPage = initialState.topPage
    },
  },
})

export const actions = auth.actions
export default auth.reducer
