import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Document } from '../interfaces/document'
import { Pagination } from '../interfaces/api'

interface State {
  documents: Document[] | null
  paginationDocuments: Pagination | null
  errorMessage: string | null
  waitingDocuments: boolean
}

const initialState: State = {
  documents: null,
  paginationDocuments: null,
  errorMessage: null,
  waitingDocuments: false,
}

const document = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setWaitingDocuments: (state) => {
      state.waitingDocuments = true
    },
    setDocuments: (
      state,
      action: PayloadAction<{ documents: Document[] }>,
    ): void => {
      state.documents = action.payload.documents
      state.waitingDocuments = false
    },
    setPaginationDocuments: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationDocuments = action.payload.pagination
    },
    resetWaitingDocuments: (state) => {
      state.waitingDocuments = initialState.waitingDocuments
    },
  },
})

export const actions = document.actions
export default document.reducer
