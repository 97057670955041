import { Snackbar, SnackbarOptions } from '../interfaces/snackbar'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  snackbars: Snackbar[]
}

interface SnackbarAction {
  message: string
  options: SnackbarOptions
}

const initialState: State = {
  snackbars: [],
}

const snackbars = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    enqueue: (state, action: PayloadAction<SnackbarAction>) => {
      const snackbar = {
        key:
          action.payload.options?.key || new Date().getTime() + Math.random(),
        message: action.payload.message,
        options: action.payload.options,
      }
      state.snackbars.push(snackbar)
    },
    remove: (state, action: PayloadAction<string | number>): void => {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.key !== action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action): action is PayloadAction<{ message: string }> =>
        action.type.endsWith('/rejected'),
      (state, action) => {
        const message =
          action?.payload?.message || 'システムエラーが発生しました'
        const payload: SnackbarAction = {
          message,
          options: { variant: 'error' },
        }
        snackbars.caseReducers.enqueue(state, { payload, type: action.type })
      },
    )
  },
})

export const actions = snackbars.actions
export default snackbars.reducer
