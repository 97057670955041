import { Backdrop, CircularProgress, styled } from '@mui/material'

type Props = {
  className?: string
  open: boolean
  hiddenProgress?: boolean
}

const OverlayLoading = ({ className, open, hiddenProgress = false }: Props) => (
  <Container className={className} open={open}>
    {!hiddenProgress && <CircularProgress color="inherit" />}
  </Container>
)

const Container = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.common.white,
}))

export default OverlayLoading
