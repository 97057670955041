import { api, apiVersion } from '../base'
import { Role, AllOfficesRoles } from '../../interfaces/setting'
import { ApiDataResponse } from '../../interfaces/api'

export type RolesData = {
  roles: Role[]
}

type RolesResponse = ApiDataResponse<RolesData>
type AllOffincesRolesResponse = ApiDataResponse<AllOfficesRoles>

export const getRoles = (): Promise<RolesResponse> =>
  api.get(`${apiVersion}/settings/roles`)

export const getAllOfficesRoles = (): Promise<AllOffincesRolesResponse> =>
  api.get(`${apiVersion}/settings/roles/all_offices_roles`)
