import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { InterviewHistory } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../../store'
import { getInterviewHistories } from '../../../apis/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

const adapter = createEntityAdapter<InterviewHistory>()

export const fetchInterviewHistoriesAction = createAsyncThunk(
  'interviewHistories/fetchInterviewHistories',
  async (interviewId: number | string, { rejectWithValue }) => {
    try {
      return await getInterviewHistories(interviewId).then(
        checkResponseDataStatus,
      )
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({ message: '面談履歴一覧の取得に失敗しました' })
    }
  },
)

const interviewHistoriesSlice = createSlice({
  name: 'interviewHistories',
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchInterviewHistoriesAction.fulfilled,
      (state, action) => {
        adapter.setAll(state, action.payload.interviewHistories)
      },
    )
  },
})

export const { selectAll: selectInterviewHistories } = adapter.getSelectors(
  (state: RootState) => state.interview.interviewHistories,
)

export default interviewHistoriesSlice.reducer
