import { Department } from '../interfaces/department'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DepartmentsData } from '../apis/setting'
import { ImportErrorResponse, Pagination } from '../interfaces/api'

interface State {
  departments: Department[] | null
  department: Department | null
  paginationDepartments: Pagination | null
  waitingDepartments: boolean
  waitingDepartment: boolean
  waitingUploadCsv: boolean
  waitingDownloadCsv: boolean
  errorResponseResults: ImportErrorResponse[] | null
}

const initialState: State = {
  departments: null,
  department: null,
  paginationDepartments: null,
  waitingDepartments: false,
  waitingDepartment: false,
  waitingUploadCsv: false,
  waitingDownloadCsv: false,
  errorResponseResults: null,
}

const department = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setWaitingDepartments: (state): void => {
      state.waitingDepartments = true
    },
    setWaitingBranch: (state): void => {
      state.waitingDepartment = true
    },
    setDepartments: (state, actions: PayloadAction<DepartmentsData>): void => {
      state.departments = actions.payload.departments
      state.waitingDepartments = initialState.waitingDepartments
    },
    setDepartment: (state, actions: PayloadAction<Department>): void => {
      state.department = actions.payload
      state.waitingDepartment = false
    },
    setPaginationDepartments: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationDepartments = actions.payload.pagination
    },
    resetDepartments: (state) => {
      state.departments = initialState.departments
      state.paginationDepartments = initialState.paginationDepartments
    },
    resetDepartment: (state) => {
      state.department = initialState.department
    },
    setWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = true
    },
    setWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = true
    },
    resetWaitingUploadCsv: (state): void => {
      state.waitingUploadCsv = initialState.waitingUploadCsv
    },
    resetWaitingDownloadCsv: (state): void => {
      state.waitingDownloadCsv = initialState.waitingDownloadCsv
    },
    setErrorResponseResults: (
      state,
      actions: PayloadAction<{ errors: ImportErrorResponse[] }>,
    ): void => {
      state.errorResponseResults = actions.payload.errors
    },
    resetErrorResponseResults: (state): void => {
      state.errorResponseResults = initialState.errorResponseResults
    },
  },
})

export const actions = department.actions
export default department.reducer
