import { styled } from '@mui/material'
import BasicLabel from './BasicLabel'
import { colors } from '../../styles/colors'

type Props = {
  className?: string
  label?: string
}

const PlusLabel = ({ className, label = 'PLUS' }: Props) => (
  <Container className={className} label={label} />
)

const Container = styled(BasicLabel)({
  fontWeight: 500,
  backgroundColor: colors.success['300'],
})

export default PlusLabel
