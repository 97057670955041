import { StoreStatus } from '../../../interfaces/api'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { InterviewReference } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../../store'
import {
  getInterviewReferences,
  InterviewReferencesData,
} from '../../../apis/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

interface State {
  status: StoreStatus
}

const initial: State = {
  status: 'idle',
}

const adapter = createEntityAdapter<InterviewReference>()
const initialState = adapter.getInitialState(initial)

export const fetchInterviewReferencesAction = createAsyncThunk<
  InterviewReferencesData,
  void,
  { state: RootState }
>(
  'interviewReferences/fetchInterviewReferences',
  async (_, { rejectWithValue }) => {
    try {
      return await getInterviewReferences().then(checkResponseDataStatus)
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({ message: '参照項目一覧の取得に失敗しました' })
    }
  },
  {
    condition: (_, { getState }) =>
      selectInterviewReferencesTotal(getState()) === 0,
  },
)

const interviewReferencesSlice = createSlice({
  name: 'interviewReferences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterviewReferencesAction.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchInterviewReferencesAction.fulfilled, (state, action) => {
        state.status = 'succeeded'
        adapter.setAll(state, action.payload.references)
      })
      .addCase(fetchInterviewReferencesAction.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const {
  selectAll: selectInterviewReferences,
  selectTotal: selectInterviewReferencesTotal,
} = adapter.getSelectors(
  (state: RootState) => state.interview.interviewReferences,
)
export const selectInterviewReferencesStatus = (
  state: RootState,
): State['status'] => state.interview.interviewReferences.status

export default interviewReferencesSlice.reducer
