import { api, apiVersion } from '../../base'
import {
  InterviewHistoriesResponse,
  InterviewParams,
  InterviewSendMailParams,
  InterviewReasonsResponse,
  InterviewReferencesResponse,
  InterviewResponse,
  InterviewsQuery,
  InterviewsResponse,
  InterviewTemplatesResponse,
  InterviewWaysResponse,
} from './types'
import { ApiResponse } from '../../../interfaces/api'

const common = `${apiVersion}/share_navi/interviews`

export const getInterviews = (query?: InterviewsQuery) =>
  api.get<InterviewsResponse>(common, query)

export const getInterview = (interviewId: string | number) =>
  api.get<InterviewResponse>(`${common}/${interviewId}`)

export const postInterview = (params: InterviewParams) =>
  api.post<InterviewResponse>(common, params)

export const sendMailInterview = (params: InterviewSendMailParams) =>
  api.post<InterviewResponse>(`${common}/send_mail`, params)

export const patchInterview = (
  interviewId: string | number,
  params: InterviewParams,
) => api.patch<InterviewResponse>(`${common}/${interviewId}`, params)

export const deleteInterview = (interviewId: string | number) =>
  api.delete<ApiResponse>(`${common}/${interviewId}`)

export const exportInterviews = () => api.download(`${common}/export`)

export const getInterviewTemplates = () =>
  api.get<InterviewTemplatesResponse>(`${common}/templates`)

export const getInterviewHistories = (interviewId: string | number) =>
  api.get<InterviewHistoriesResponse>(`${common}/${interviewId}/histories`)

export const getInterviewReasons = () =>
  api.get<InterviewReasonsResponse>(`${common}/reasons`)

export const getInterviewWays = () =>
  api.get<InterviewWaysResponse>(`${common}/ways`)

export const getInterviewReferences = () =>
  api.get<InterviewReferencesResponse>(`${common}/references`)
