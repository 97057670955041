import {
  NaviMenuGroupSurvey,
  NaviSurvey,
  NaviSurveyDetail,
  ReinstatementCheckResult,
  WellnessCheckResult,
  DownloadPdfResult,
} from '../interfaces/navi'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NaviMenuGroupSurveysData,
  NaviSurveyData,
  NaviSurveysData,
  ReinstatementCheckResultData,
  WellnessCheckResultData,
} from '../apis/navi'
import { Pagination } from '../interfaces/api'

interface State {
  naviMenuGroupSurveys: NaviMenuGroupSurvey[] | null
  naviSurveys: NaviSurvey[] | null
  naviSurvey: NaviSurveyDetail | null

  wellnessCheckResult: WellnessCheckResult | null
  reinstatementCheckResult: ReinstatementCheckResult | null

  downloadPdfResult: DownloadPdfResult | null

  paginationNaviMenuGroupSurveys: Pagination | null
  paginationNaviSurveys: Pagination | null

  waitingNaviMenuGroupSurveys: boolean
  waitingNaviSurveys: boolean
  waitingWellnessCheckResult: boolean
  waitingReinstatementCheckResult: boolean
}

const initialState: State = {
  naviMenuGroupSurveys: null,
  naviSurveys: null,
  naviSurvey: null,

  wellnessCheckResult: null,
  reinstatementCheckResult: null,

  downloadPdfResult: null,

  paginationNaviMenuGroupSurveys: null,
  paginationNaviSurveys: null,

  waitingNaviMenuGroupSurveys: false,
  waitingNaviSurveys: false,
  waitingWellnessCheckResult: false,
  waitingReinstatementCheckResult: false,
}

const navi = createSlice({
  name: 'navi',
  initialState,
  reducers: {
    setWaitingNaviMenuGroupSurveys: (state) => {
      state.waitingNaviMenuGroupSurveys = true
    },
    setWaitingNaviSurveys: (state) => {
      state.waitingNaviSurveys = true
    },
    setWaitingWellnessCheckResult: (state) => {
      state.waitingWellnessCheckResult = true
    },
    setWaitingReinstatementCheckResult: (state) => {
      state.waitingReinstatementCheckResult = true
    },
    setNaviMenuGroupSurveys: (
      state,
      action: PayloadAction<NaviMenuGroupSurveysData>,
    ) => {
      state.naviMenuGroupSurveys = action.payload.surveys
    },
    setNaviSurveys: (state, action: PayloadAction<NaviSurveysData>) => {
      state.naviSurveys = action.payload.surveys
    },
    setNaviSurvey: (state, action: PayloadAction<NaviSurveyData>) => {
      state.naviSurvey = action.payload
    },
    setWellnessCheckResult: (
      state,
      action: PayloadAction<WellnessCheckResultData>,
    ) => {
      state.wellnessCheckResult = action.payload.wellnessCheckResult
    },
    setReinstatementCheckResult: (
      state,
      action: PayloadAction<ReinstatementCheckResultData>,
    ) => {
      state.reinstatementCheckResult = action.payload.reinstatementCheckResult
    },
    setDownloadPdf: (state, action: PayloadAction<DownloadPdfResult>) => {
      state.downloadPdfResult = action.payload
    },
    setPaginationNaviMenuGroupSurveys: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationNaviMenuGroupSurveys = actions.payload.pagination
    },
    setPaginationNaviSurveys: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationNaviSurveys = actions.payload.pagination
    },
    resetNaviSurveys: (state) => {
      state.naviSurveys = initialState.naviSurveys
    },
    resetNaviSurvey: (state) => {
      state.naviSurvey = initialState.naviSurvey
    },
    resetWellnessCheckResult: (state) => {
      state.wellnessCheckResult = initialState.wellnessCheckResult
    },
    resetReinstatementCheckResult: (state) => {
      state.reinstatementCheckResult = initialState.reinstatementCheckResult
    },
    resetWaitingNaviMenuGroupSurveys: (state) => {
      state.waitingNaviMenuGroupSurveys =
        initialState.waitingNaviMenuGroupSurveys
    },
    resetWaitingNaviSurveys: (state) => {
      state.waitingNaviSurveys = initialState.waitingNaviSurveys
    },
    resetWaitingWellnessCheckResult: (state) => {
      state.waitingWellnessCheckResult = initialState.waitingWellnessCheckResult
    },
    resetWaitingReinstatementCheckResult: (state) => {
      state.waitingReinstatementCheckResult =
        initialState.waitingReinstatementCheckResult
    },
  },
})

export const actions = navi.actions
export default navi.reducer
