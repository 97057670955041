import { api, apiVersion } from '../base'
import { ApiDataResponse } from '../../interfaces/api'
import { User, UserSummary } from '../../interfaces/user'

export type UsersQuery = {
  /** 名前 */
  name?: string | null
  /** 権限ID配列 */
  roleIds?: number[]
  /** 従業員存在判定 */
  isEmployee?: 1 | 0
}

export type UserParams = {
  id: number
  name?: string
  email?: string
  password?: string
  passwordConfirmation?: string
  passwordOld?: string
  currentUserRoleId?: number
}

export type UsersData = {
  users: UserSummary[]
}

type UsersResponse = ApiDataResponse<UsersData>
type UserResponse = ApiDataResponse<User>

const common = `${apiVersion}/settings/users`

export const getUsers = (query?: UsersQuery) =>
  api.get<UsersResponse>(common, query)

export const getUser = (userId: number | string) =>
  api.get<UserResponse>(`${common}/${userId}`)

export const putUser = (params: UserParams): Promise<UserResponse> =>
  api.put(`${common}/update`, params)
