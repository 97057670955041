import { lazy } from 'react'
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom'
import { Login } from '@/pages/session/Login'
import { ForgotPassword } from '@/pages/session/ForgotPassword'
import { ResetPassword } from '@/pages/session/ResetPassword'
import { isLogin } from '@/utils/userStorage'

const Layout = lazy(
  () => import(/* webpackChunkName: "main" */ '../pages/app/Layout'),
)

const AuthenticatedRoute = () => {
  if (!isLogin()) {
    return <Navigate to="/login" replace />
  } else {
    return <Layout />
  }
}

const App = () => (
  <Routes>
    <Route path="*" element={<AuthenticatedRoute />} />
  </Routes>
)

const router = createBrowserRouter([
  { path: '/login', element: <Login /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:token', element: <ResetPassword /> },
  { path: '*', element: <App /> },
])

export const RouteProvider = () => <RouterProvider router={router} />
