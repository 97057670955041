import * as React from 'react'
import { Typography, Card, CardContent } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  }),
)

export const OtpMail: React.FC = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Typography component="h6" variant="h6">
            認証コードを送信しました
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            ご登録のメールアドレスに6桁の認証コードを送信しました。
            <br />
          </Typography>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}
