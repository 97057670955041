import { EmployeeStatus } from '../../interfaces/employee'
import { ApiDataResponse } from '../../interfaces/api'
import { api, apiVersion } from '../base'

export type EmployeeStatusesData = {
  employeeStatuses: EmployeeStatus[]
}

type EmployeeStatusesResponse = ApiDataResponse<EmployeeStatusesData>

export const getEmployeeStatuses = (): Promise<EmployeeStatusesResponse> =>
  api.get(`${apiVersion}/settings/employees/status`)
