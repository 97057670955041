import { api, apiVersion } from '../base'
import {
  ApiDataResponse,
  ApiImportResponse,
  ApiResponse,
} from '../../interfaces/api'
import { Employee, EmployeeDetail } from '../../interfaces/employee'
import { EmployeeStatusSummary } from '../../interfaces/dashboard'

export type EmployeesParams = {
  branchIds?: number[]
  departmentIds?: number[]
  employeeTitleIds?: number[]
  employeeStatusIds?: number[]
  name?: string | null
  page?: number
}

export type EmployeeParams = Pick<
  Employee,
  | 'code'
  | 'name'
  | 'branchId'
  | 'departmentId'
  | 'employeeStatusId'
  | 'employeeTitleId'
  | 'gender'
  | 'birthday'
  | 'statusFrom'
  | 'statusTo'
>

export type EmployeeUpdateParams = EmployeeParams & Pick<Employee, 'id'>

export type EmployeesData = {
  employees: Employee[]
}
export type EmployeeStatusSummaryData = {
  employeeStatusSummary: EmployeeStatusSummary
}

type EmployeesResponse = ApiDataResponse<EmployeesData>
type EmployeeResponse = ApiDataResponse<EmployeeDetail>
export type EmployeeStatusSummaryResponse =
  ApiDataResponse<EmployeeStatusSummaryData>

const common = `${apiVersion}/settings/employees`

export const getEmployees = (params?: EmployeesParams) =>
  api.get<EmployeesResponse>(`${common}`, params)

export const getEmployee = (employeeId: number | string) =>
  api.get<EmployeeResponse>(`${common}/${employeeId}`)

export const postEmployee = (
  params: EmployeeParams,
): Promise<EmployeeResponse> => api.post(`${common}/create`, params)

export const putEmployee = (
  params: EmployeeUpdateParams,
): Promise<EmployeeResponse> => api.put(`${common}/update`, params)

export const deleteEmployee = (params: { id: number }): Promise<ApiResponse> =>
  api.delete(`${common}/delete`, params)

export const getEmployeeStatusSummary = (
  params?: EmployeesParams,
): Promise<EmployeeStatusSummaryResponse> =>
  api.get(`${common}/employee_status_summary`, params)

export const importEmployeeResults = (params: {
  file: File
}): Promise<ApiImportResponse> => {
  const formData = new FormData()
  formData.append('file', params.file)
  const url = `${common}/setting_csv_import`
  return api.multiPart(url, formData)
}
export const exportEmployeeResults = (): Promise<Blob> => {
  const url = `${common}/setting_csv_export`
  return api.download(url)
}
