import { api, apiVersion } from '../base'
import { ApiDataResponse } from '../../interfaces/api'
import { EmployeeGender } from '../../interfaces/employee'

export type EmployeeGendersData = {
  employeeGenders: EmployeeGender[]
}

type EmployeeGendersResponse = ApiDataResponse<EmployeeGendersData>

export const getEmployeeGenders = (): Promise<EmployeeGendersResponse> =>
  api.get(`${apiVersion}/settings/employees/gender`)
