import { CheckupResultSummary } from '../interfaces/healthCare/checkup'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CheckupResultSummaryData,
  EmployeePostsData,
  WellnessLiteResultData,
  SickSurveyResultsData,
  EmploymentHistoriesData,
  InterviewsSummaryData,
  OverworkSummaryData,
  StressCheckSummaryData,
} from '../apis/karte'
import { EmployeePost, EmploymentHistory } from '../interfaces/employee'
import { PersonnelReport } from '../interfaces/shareNavi/personnelReport'
import { PersonnelReportsData } from '../apis/shareNavi/personnelReport'
import { StressCheckSummary } from '../interfaces/healthCare/stressCheck'
import { OverworkSummary } from '../interfaces/healthCare/overwork'
import { InterviewSummary } from '../interfaces/shareNavi/interview'
import { Pagination } from '../interfaces/api'
import { WellnessLiteResult } from '../interfaces/detection'
import { EmployeeSurveyResult } from '../interfaces/navi'

interface State {
  employeePosts: EmployeePost[] | null
  paginationEmployeePosts: Pagination | null
  employeePost: EmployeePost | null
  wellnessLiteResults: WellnessLiteResult[] | null
  paginationWellnessLiteResults: Pagination | null
  employmentHistories: EmploymentHistory[] | null
  checkupResults: CheckupResultSummary[] | null
  paginationCheckupResults: Pagination | null
  personnelReports: PersonnelReport[] | null
  sickSurveyResults: EmployeeSurveyResult[] | null
  paginationSickSurveyResults: Pagination | null
  stressCheckSurveys: StressCheckSummary[] | null
  paginationStressCheckSurveys: Pagination | null
  overworkSurveys: OverworkSummary[] | null
  paginationOverworkSurveys: Pagination | null
  interviews: InterviewSummary[] | null
  paginationInterviews: Pagination | null
  waitingEmployeePosts: boolean
  waitingWellnessLiteResults: boolean
  waitingEmploymentHistories: boolean
  waitingCheckupResults: boolean
  waitingPersonnelReports: boolean
  waitingSickSurveyResults: boolean
  waitingStressCheckSurveys: boolean
  waitingOverworkSurveys: boolean
  waitingInterviews: boolean
}

const initialState: State = {
  employeePosts: null,
  paginationEmployeePosts: null,
  employeePost: null,
  wellnessLiteResults: null,
  paginationWellnessLiteResults: null,
  employmentHistories: null,
  checkupResults: null,
  paginationCheckupResults: null,
  personnelReports: null,
  sickSurveyResults: null,
  paginationSickSurveyResults: null,
  stressCheckSurveys: null,
  paginationStressCheckSurveys: null,
  overworkSurveys: null,
  paginationOverworkSurveys: null,
  interviews: null,
  paginationInterviews: null,
  waitingEmployeePosts: false,
  waitingWellnessLiteResults: false,
  waitingEmploymentHistories: false,
  waitingCheckupResults: false,
  waitingPersonnelReports: false,
  waitingSickSurveyResults: false,
  waitingStressCheckSurveys: false,
  waitingOverworkSurveys: false,
  waitingInterviews: false,
}

const karte = createSlice({
  name: 'karte',
  initialState,
  reducers: {
    setWaitingEmployeePosts: (state) => {
      state.waitingEmployeePosts = true
    },
    setWaitingWelnessLiteResults: (state) => {
      state.waitingWellnessLiteResults = true
    },
    setWaitingEmploymentHistories: (state) => {
      state.waitingEmploymentHistories = true
    },
    setWaitingCheckupResults: (state) => {
      state.waitingCheckupResults = true
    },
    setWaitingPersonnelReports: (state) => {
      state.waitingPersonnelReports = true
    },
    setWaitingSickSurveyResults: (state) => {
      state.waitingSickSurveyResults = true
    },
    setWaitingStressCheckSurveys: (state) => {
      state.waitingStressCheckSurveys = true
    },
    setWaitingOverworkSurveys: (state) => {
      state.waitingOverworkSurveys = true
    },
    setWaitingInterviews: (state) => {
      state.waitingInterviews = true
    },
    setEmployeePosts: (state, action: PayloadAction<EmployeePostsData>) => {
      state.employeePosts = action.payload.employeePosts
    },
    setPaginationEmployeePosts: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationEmployeePosts = action.payload.pagination
    },
    setEmployeePost: (state, action: PayloadAction<EmployeePost>) => {
      state.employeePost = action.payload
    },
    setWellnessLiteResults: (
      state,
      action: PayloadAction<WellnessLiteResultData>,
    ) => {
      state.wellnessLiteResults = action.payload.wlSurveys
    },
    setPaginationWelnessLiteResults: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationWellnessLiteResults = action.payload.pagination
    },
    setEmploymentHistories: (
      state,
      action: PayloadAction<EmploymentHistoriesData>,
    ) => {
      state.employmentHistories = action.payload.employmentHistories
    },
    setCheckupResults: (
      state,
      action: PayloadAction<CheckupResultSummaryData>,
    ) => {
      state.checkupResults = action.payload.checkupSurveys
      state.waitingCheckupResults = false
    },
    setPaginationCheckupResults: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationCheckupResults = action.payload.pagination
    },
    setPersonnelReports: (
      state,
      action: PayloadAction<PersonnelReportsData>,
    ) => {
      state.personnelReports = action.payload.personnelReports
      state.waitingPersonnelReports = false
    },
    setSickSurveyResults: (
      state,
      action: PayloadAction<SickSurveyResultsData>,
    ) => {
      state.sickSurveyResults = action.payload.surveys
      state.waitingSickSurveyResults = false
    },
    setPaginationSickSurveyResults: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationSickSurveyResults = action.payload.pagination
    },
    setStressCheckSurveys: (
      state,
      action: PayloadAction<StressCheckSummaryData>,
    ) => {
      state.stressCheckSurveys = action.payload.stressCheckSurveys
    },
    setPaginationStressCheckSurveys: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationStressCheckSurveys = action.payload.pagination
    },
    setOverworkSurveys: (state, action: PayloadAction<OverworkSummaryData>) => {
      state.overworkSurveys = action.payload.overworkSurveys
    },
    setPaginationOverworkSurveys: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationOverworkSurveys = action.payload.pagination
    },
    setInterviews: (state, action: PayloadAction<InterviewsSummaryData>) => {
      state.interviews = action.payload.interviews
    },
    setPaginationInterviews: (
      state,
      action: PayloadAction<{ pagination: Pagination | null }>,
    ) => {
      state.paginationInterviews = action.payload.pagination
    },
    resetEmployeePosts: (state) => {
      state.employeePosts = initialState.employeePosts
    },
    resetEmployeePost: (state) => {
      state.employeePost = initialState.employeePost
    },
    resetEmploymentHistories: (state) => {
      state.employmentHistories = initialState.employmentHistories
    },
    resetCheckupResults: (state) => {
      state.checkupResults = initialState.checkupResults
    },
    resetPersonnelReports: (state) => {
      state.personnelReports = initialState.personnelReports
    },
    resetStressCheckSurveys: (state) => {
      state.stressCheckSurveys = initialState.stressCheckSurveys
    },
    resetOverworkSurveys: (state) => {
      state.overworkSurveys = initialState.overworkSurveys
    },
    resetWaitingEmployeePosts: (state) => {
      state.waitingEmployeePosts = initialState.waitingEmployeePosts
    },
    resetWaitingStressCheckSurveys: (state) => {
      state.waitingStressCheckSurveys = initialState.waitingStressCheckSurveys
    },
    resetWaitingOverworkSurveys: (state) => {
      state.waitingOverworkSurveys = initialState.waitingOverworkSurveys
    },
    resetWaitingInterviews: (state) => {
      state.waitingInterviews = initialState.waitingInterviews
    },
  },
})

export const actions = karte.actions
export default karte.reducer
