import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Box,
  Button,
  Container,
  ContainerProps,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  ApiResetPassword,
  ApiResetPasswordTokenConfirm,
} from '../../apis/session'
import { PrimaryButton } from '../../components/Button/PrimaryButton'
import { getUserInfo } from '../../utils/userStorage'
import { Copyright } from './Copyright'
import { OtpDialog } from '../auth/OtpDialog'
import { useAppDispatch } from '../../hooks'
import { getUserTopPage } from '../../services/auth/userRole'

const PasswordMessage =
  '8〜72文字で「¥」「\\」「[]」以外の英数・記号で設定してください'

export const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [validErrorAll, setValidErrorAll] = useState(true)
  const [validPassword, setValidPassword] = useState({
    value: '',
    error: false,
    message: PasswordMessage,
    inputRef: useRef<HTMLInputElement>(null),
    showPassword: false,
  })
  const [validPasswordConfirm, setValidPasswordConfirm] = useState({
    value: '',
    error: false,
    message: '',
    inputRef: useRef<HTMLInputElement>(null),
    showPassword: false,
  })
  const [loginCount, setLoginCount] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const [otpData, setOtpData] = useState({
    userId: 0,
    email: '',
    otpAuth: 0,
    otpAuthName: '',
    lastOtpDate: '',
    otpSecret: '',
  })
  const [isOpenOtp, setIsOpenOtp] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const userInfo = getUserInfo()
    if (userInfo) {
      setLoginCount(userInfo.loginCount ?? 0)
      setOtpData({
        ...otpData,
        userId: userInfo.id ?? 0,
        email: userInfo.email ?? '',
        otpAuth: userInfo.otpAuth ?? 0,
        otpAuthName: userInfo.otpAuthName ?? '',
        lastOtpDate: userInfo.lastOtpDate ?? '',
        otpSecret: userInfo.otpSecret ?? '',
      })
    }
    fetchResetPasswordTokenConfirm()
  }, [])

  const fetchResetPasswordTokenConfirm = () => {
    ApiResetPasswordTokenConfirm({ resetPasswordToken: token }).then(
      (response) => {
        try {
          if (response.status === 'error') {
            setDisabled(true)
            return enqueueSnackbar(response.message, {
              variant: 'error',
              key: 'resetPassword',
            })
          }
        } catch (e) {
          setDisabled(true)
          return enqueueSnackbar('このURLからはログインできません', {
            variant: 'error',
            key: 'resetPassword',
          })
        }
      },
    )
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validPassword.inputRef.current === null) {
      return
    }
    if (!validPassword.inputRef.current.validity.valid) {
      setValidErrorAll(true)
      setValidPassword({
        ...validPassword,
        error: true,
        message: PasswordMessage,
        value: event.currentTarget.value,
      })
    } else {
      if (validPasswordConfirm.value.length > 0) {
        if (validPasswordConfirm.value != event.currentTarget.value) {
          setValidErrorAll(true)
          setValidPassword({
            ...validPasswordConfirm,
            error: false,
            message: '',
            value: event.currentTarget.value,
          })
          setValidPasswordConfirm({
            ...validPasswordConfirm,
            error: true,
            message: '確認用に新しいパスワードと同じもの入力してください',
          })
        } else {
          successValidPassword(event.currentTarget.value)
        }
      } else {
        successValidPassword(event.currentTarget.value)
      }
    }
  }
  const successValidPassword = (value: string) => {
    validationAll(
      value,
      validPasswordConfirm.value,
      false,
      validPasswordConfirm.error,
    )
    setValidPassword({
      ...validPassword,
      error: false,
      message: '',
      value: value,
    })
  }

  const handleChangePasswordConfirm = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (validPassword.value != event.currentTarget.value) {
      setValidErrorAll(true)
      setValidPasswordConfirm({
        ...validPasswordConfirm,
        error: true,
        message: '確認用に新しいパスワードと同じもの入力してください',
        value: event.currentTarget.value,
      })
    } else {
      validationAll(
        validPassword.value,
        event.currentTarget.value,
        validPassword.error,
        false,
      )
      setValidPasswordConfirm({
        ...validPasswordConfirm,
        error: false,
        message: '',
        value: event.currentTarget.value,
      })
    }
  }

  const validationAll = (
    value1: string,
    value2: string,
    error1: boolean,
    error2: boolean,
  ) => {
    if (value1.length > 0 && value2.length > 0 && !error1 && !error2) {
      setValidErrorAll(false)
    } else {
      setValidErrorAll(true)
    }
  }

  const handleClickShowPassword = () => {
    setValidPassword({
      ...validPassword,
      showPassword: !validPassword.showPassword,
    })
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const handleLogin = () => {
    navigate('../login')
  }

  const handleSuccessOtp = async () => {
    // ログイン完了
    const topPage = await dispatch(getUserTopPage())
    navigate(topPage)
    setIsOpenOtp(false)
  }

  const handleCancelOtp = () => {
    // ダイアログ・キャンセルの場合は、ログイン画面へ
    navigate('../login')
    setIsOpenOtp(false)
  }

  const handleResetPasswordSubmit = () => {
    if (
      validPassword.inputRef.current &&
      validPassword.inputRef.current.validity.valid &&
      validPassword.value === validPasswordConfirm.value
    ) {
      ApiResetPassword({
        resetPasswordToken: token,
        password: validPassword.value,
        passwordConfirmation: validPasswordConfirm.value,
      }).then((response) => {
        try {
          if (response.status === 'success') {
            if (loginCount == 1) {
              // 初回ログイン時はそのまま、２段階認証設定へ
              setIsOpenOtp(true)
            } else {
              enqueueSnackbar('パスワードをリセットしました', {
                variant: 'success',
                key: 'resetPassword',
              })
            }
          }
          if (response.status === 'error') {
            return enqueueSnackbar(response.message, {
              variant: 'error',
              key: 'resetPassword',
            })
          }
        } catch (e) {
          return enqueueSnackbar('ログインリンクの送信に失敗しました', {
            variant: 'error',
            key: 'forgotPassword',
          })
        }
      })
    }
  }

  return (
    <React.Fragment>
      <ContainerStyled component="main" maxWidth="xs">
        <Paper>
          <AvatarStyled>
            <Lock fontSize="large" />
          </AvatarStyled>
          <Typography component="h1" variant="h5">
            パスワードリセット
          </Typography>
          <Box mb={2}>
            <Typography component="h6" variant="caption">
              アカウントの新しいパスワードを設定してください。
            </Typography>
          </Box>
          {loginCount == 1 && (
            <Typography component="h6" variant="h6">
              仮パスワードを変更して下さい
            </Typography>
          )}

          <TextField
            id="newpasstext"
            required
            variant="outlined"
            label="新しいパスワード"
            type={validPassword.showPassword ? 'text' : 'password'}
            disabled={disabled}
            value={validPassword.value}
            onChange={handleChangePassword}
            margin="normal"
            fullWidth
            name="new_password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockStyled />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    {validPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              minLength: 8,
              maxLength: 72,
              pattern: '^[a-zA-Z0-9_!@#$%^&*()+-={};:?,.]+$',
            }}
            inputRef={validPassword.inputRef}
            error={validPassword.error}
            helperText={validPassword.message}
          />

          <TextField
            id="newpassconfirmtext"
            required
            variant="outlined"
            label="新しいパスワードの確認"
            type="password"
            disabled={disabled}
            value={validPasswordConfirm.value}
            onChange={handleChangePasswordConfirm}
            margin="normal"
            fullWidth
            name="new_password_confirm"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockStyled />
                </InputAdornment>
              ),
            }}
            inputProps={{
              minLength: 8,
              maxLength: 72,
              pattern: '^[a-zA-Z0-9_!@#$%^&*()+-={};:?,.]+$',
            }}
            inputRef={validPasswordConfirm.inputRef}
            error={validPasswordConfirm.error}
            helperText={validPasswordConfirm.message}
          />
          <PrimaryButtonStyled
            variant="contained"
            disabled={validErrorAll}
            onClick={handleResetPasswordSubmit}
          >
            パスワードを変更する
          </PrimaryButtonStyled>
        </Paper>

        <Paper>
          <Button color="primary" onClick={handleLogin}>
            ログイン画面へ
          </Button>
        </Paper>

        <Box mt={8}>
          <Copyright />
        </Box>
      </ContainerStyled>

      <OtpDialog
        isOpen={isOpenOtp}
        isSelect
        userId={otpData.userId}
        email={otpData.email}
        otpAuth={otpData.otpAuth}
        otpAuthName={otpData.otpAuthName}
        lastOtpDate={otpData.lastOtpDate}
        otpSecret={otpData.otpSecret}
        onSuccess={handleSuccessOtp}
        onCancel={handleCancelOtp}
      />
    </React.Fragment>
  )
}

const ContainerStyled = styled(Container)<ContainerProps>(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(20),
}))

const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const PrimaryButtonStyled = styled(PrimaryButton)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}))

const LockStyled = styled(Lock)(({ theme }) => ({
  fontSize: 20,
  marginLeft: theme.spacing(1),
  opacity: 0.8,
}))

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(7),
  backgroundColor: theme.palette.secondary.main,
}))
