import { ReactNode } from 'react'
import { SWRConfig } from 'swr'
import { useAppDispatch } from '@/hooks'
import { enqueueErrorSnackbar } from '@/services/snackbars'

export type CacheProviderProps = {
  children: ReactNode
}

export const CacheProvider = ({ children }: CacheProviderProps) => {
  const dispatch = useAppDispatch()
  return (
    <SWRConfig
      value={{
        /* エラー時に再取得しない */
        errorRetryCount: 0,
        onError: (error) => {
          const message = error?.message || 'データを取得できませんでした'
          dispatch(enqueueErrorSnackbar(message))
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}
