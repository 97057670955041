import { string } from '../constants/string'
import { environment, environmentValue } from '../constants'

/**
 * API基底URLの取得
 *
 * APIの基底URLを取得する。
 * サブドメインが必要な場合には追加して返却する。
 *
 * @param location window.location オブジェクト
 * @param envApi API関連環境変数(デフォルトは .env の参照値)
 */
export const getApiBaseUrl = (
  location: Location,
  envApi = environment.api,
): string => {
  // サブドメインを利用しないならば、基底URLを返却する(従来の挙動)
  if (envApi.ENFORCE_SUBDOMAIN !== environmentValue.ENABLE) {
    return envApi.BASE
  }

  // 現在の hostname と env.api.BASE が同一接続先ならば
  // (プロトコル,ドメイン,ポート番号も含む)何もしない
  if (envApi.BASE === location.origin) {
    return envApi.BASE
  }

  const subdomain = getSubDomain(location)
  const url = new URL(envApi.BASE)

  // 接続先URLにサブドメインを追加
  url.hostname = [subdomain, ...url.hostname.split(string.PERIOD)].join(
    string.PERIOD,
  )

  return url.origin
}

/**
 * サブドメインの取得
 *
 * サブドメインを取得する。
 * 取得できない場合にはエラーをスローする。
 *
 * @param location window.location オブジェクト
 * @param index 取得したいサブドメインのインデックス(デフォルト:0)
 */
export const getSubDomain = ({ hostname }: Location, index = 0): string => {
  if (hostname === undefined || hostname?.length === 0) {
    throw ReferenceError('getSubDomain: ホスト名が空です')
  }

  const result = hostname.split(string.PERIOD).at(index)

  if (result === undefined) {
    throw RangeError('getSubDomain: サブドメインは存在しません')
  }

  return result
}
