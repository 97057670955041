import { getAccessToken, removeAll } from '../utils/userStorage'
import { getURLParams, URLParams } from '../utils/strings'
import { httpMessage, httpStatus } from '../constants'
import { getApiBaseUrl } from '../utils/location'

/**
 * @deprecated
 * api.V1 を利用してください。
 */
export const apiVersion = '/api/v1'

const apiFetch = (url: string, params: RequestInit): Promise<Response> =>
  fetch(getApiBaseUrl(window.location) + url, params).then((response) => {
    switch (response.status) {
      case httpStatus.OK:
      case httpStatus.CREATED:
      case httpStatus.NO_CONTENT:
      case httpStatus.UNPROCESSABLE_ENTITY:
        return response
      case httpStatus.FORBIDDEN:
        removeAll()
        throw new Error(httpMessage[response.status])
      case httpStatus.BAD_REQUEST:
      case httpStatus.UNAUTHORIZED:
      case httpStatus.NOT_FOUND:
      case httpStatus.INTERNAL_SERVER_ERROR:
      case httpStatus.BAD_GATEWAY:
      default: {
        const message =
          httpMessage[response.status] || '予期しないエラーが発生しました'
        throw new Error(message)
      }
    }
  })

export const FetchExecute = <T>(url: string, params: RequestInit): Promise<T> =>
  apiFetch(url, params)
    .then((response) => response.json())
    .catch((error) => {
      console.log('--- catch error ---')
      console.log(error)
      return {
        status: 'error',
        message:
          error?.message || httpMessage[httpStatus.INTERNAL_SERVER_ERROR],
      }
    })

export const ApiUnAuthJson = (
  method: string,
  params: Record<string, unknown>,
): RequestInit => {
  return {
    method,
    body: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' },
  }
}

const json = <T>(url: string, options: RequestInit): Promise<T> => {
  const _options = {
    ...options,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  }
  return FetchExecute(url, _options)
}

const blob = (url: string, options?: RequestInit): Promise<Blob> => {
  const _options = {
    ...options,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
    },
  }
  return apiFetch(url, _options).then((response) => response.blob())
}

export const api = {
  V1: '/api/v1',
  get: <T>(url: string, query?: URLParams, init?: RequestInit): Promise<T> => {
    const _query = getURLParams(query)
    const options: RequestInit = { ...init, method: 'GET' }
    return json(`${url}${_query}`, options)
  },
  post: <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const options = { method: 'POST', body: JSON.stringify(params) }
    return json(url, options)
  },
  patch: <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const options = { method: 'PATCH', body: JSON.stringify(params) }
    return json(url, options)
  },
  put: <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const options = { method: 'PUT', body: JSON.stringify(params) }
    return json(url, options)
  },
  delete: <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const options = { method: 'DELETE', body: JSON.stringify(params) }
    return json(url, options)
  },
  multiPart: <T>(
    url: string,
    formData: FormData,
    method = 'POST',
  ): Promise<T> => {
    const options = {
      method,
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
    return FetchExecute(url, options)
  },
  download: (url: string, options?: RequestInit): Promise<Blob> => {
    const _options = { method: 'GET', ...options }
    return blob(url, _options)
  },
}
