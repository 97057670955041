import { api, apiVersion } from '../../base'
import { ApiResponse } from '../../../interfaces/api'
import { InterviewFileResponse } from './types'

const common = `${apiVersion}/share_navi/interviews`

export const getInterviewFile = (
  interviewId: string | number,
  interviewFileId: string | number,
) => api.download(`${common}/${interviewId}/files/${interviewFileId}`)

export const postInterviewFile = (
  interviewId: string | number,
  file: FormData,
) =>
  api.multiPart<InterviewFileResponse>(`${common}/${interviewId}/files`, file)

export const deleteInterviewFile = (
  interviewId: string | number,
  interviewFileId: string | number,
) =>
  api.delete<ApiResponse>(`${common}/${interviewId}/files/${interviewFileId}`)
