import {
  CheckupEventCandidate,
  CheckupEventEmployee,
} from '../../../interfaces/healthCare/checkup'
import {
  ApiDataResponse,
  ApiImportResponse,
  ApiResponse,
} from '../../../interfaces/api'
import { api, apiVersion } from '../../base'

const common = `${apiVersion}/checkup/events`

type EventEmployeesData = {
  checkupSurveys: CheckupEventEmployee[]
}
type EventCandidatesData = {
  employees: CheckupEventCandidate[]
}
type InvitedEventEmployeesData = {
  employees: [{ id: number; name: string }]
}

type EventEmployeesResponse = ApiDataResponse<EventEmployeesData>
type EventCandidatesResponse = ApiDataResponse<EventCandidatesData>
type InvitedEventEmployeesResponse = ApiDataResponse<InvitedEventEmployeesData>

export type EventEmployeesParams = {
  /** 事業所ID配列 */
  branchIds?: number[]
  /** 部署ID配列 */
  departmentIds?: number[]
  /** 就業ステータスID配列 */
  employeeStatusIds?: number[]
  /** 健診ステータスID配列 */
  eventStatusIds?: number[]
  /** アップロード日時 */
  uploadAt?: string | null
  /** 従業員コード・従業員名 */
  name?: string | null
  /** ページ */
  page?: number
}

export type EventCandidatesParams = Omit<EventEmployeesParams, 'eventStatusIds'>

export type InviteEventEmployeeConditions = Omit<EventCandidatesParams, 'page'>

export type InviteEventEmployeeParams = {
  targetType: 'ids' | 'conditions'
  ids?: number[]
  conditions?: InviteEventEmployeeConditions
}

export const getEventEmployees = (
  eventId: string,
  params?: EventEmployeesParams,
): Promise<EventEmployeesResponse> => {
  const url = `${common}/${eventId}/details`
  return api.get(url, params)
}

export const getEventCandidates = (
  eventId: string,
  params?: EventCandidatesParams,
): Promise<EventCandidatesResponse> => {
  const url = `${common}/${eventId}/candidates`
  return api.get(url, params)
}

export const postEventEmployees = (
  eventId: string,
  params: InviteEventEmployeeParams,
): Promise<InvitedEventEmployeesResponse> => {
  const url = `${common}/${eventId}/invitations`
  return api.post(url, params)
}

export const deleteEventEmployees = (
  eventId: string,
  params: { ids: number[] },
): Promise<ApiResponse> => {
  const url = `${common}/${eventId}/invitations`
  return api.delete(url, params)
}

export const importResults = (
  eventId: string,
  params: { file: File },
): Promise<ApiImportResponse> => {
  const formData = new FormData()
  formData.append('file', params.file)
  const url = `${common}/${eventId}/import`
  return api.multiPart(url, formData)
}

export const exportResults = (eventId: string): Promise<Blob> => {
  const url = `${common}/${eventId}/export`
  return api.download(url)
}
