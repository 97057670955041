import { api, apiVersion } from '../base'
import {
  ApiDataResponse,
  ApiImportResponse,
  ApiResponse,
} from '../../interfaces/api'
import { Department } from '../../interfaces/setting'

export type DepartmentsParams = {
  page?: number
}

export type DepartmentParams = Pick<Department, 'code' | 'name'>

export type DepartmentsData = {
  departments: Department[]
}

export type DepartmentUpdateParams = DepartmentsParams & Pick<Department, 'id'>

type DepartmentsResponse = ApiDataResponse<DepartmentsData>
type DepartmentResponse = ApiDataResponse<Department>
const common = `${apiVersion}/settings/departments`

export const getDepartments = (): Promise<DepartmentsResponse> =>
  api.get(`${apiVersion}/settings/departments`)

export const getDepartmentList = (params?: DepartmentsParams) =>
  api.get<DepartmentsResponse>(`${common}/list`, params)

export const getDepartment = (departmentId: number | string) =>
  api.get<DepartmentResponse>(`${common}/${departmentId}`)

export const postDepartment = (
  params: DepartmentParams,
): Promise<DepartmentResponse> => api.post(`${common}/create`, params)

export const putDepartment = (
  params: DepartmentUpdateParams,
): Promise<DepartmentResponse> => api.put(`${common}/update`, params)

export const deleteDepartment = (params: {
  id: number
}): Promise<ApiResponse> => api.delete(`${common}/delete`, params)

export const importDepartmentResults = (params: {
  file: File
}): Promise<ApiImportResponse> => {
  const formData = new FormData()
  formData.append('file', params.file)
  const url = `${common}/setting_csv_import`
  return api.multiPart(url, formData)
}

export const exportResults = (): Promise<Blob> => {
  const url = `${common}/setting_csv_export`
  return api.download(url)
}

export const getStressCheckDepartmentOptions = (
  stressCheckId: number | string,
): Promise<DepartmentsResponse> =>
  api.get(
    `${apiVersion}/health_care/stress_checks/${stressCheckId}/departments_index`,
  )
