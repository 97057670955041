export const colors = {
  primary: {
    main: '#4184da',
  },
  secondary: {
    main: '#f0547c',
  },

  /** アクセントカラー(サイドメニュー) */
  accent: {
    /*
     * Material-UI Theme Creator
     *
     * プライマリカラーを設定した際に、ライト色とダーク色を生成する。
     * https://bareynol.github.io/mui-theme-creator/
     */
    main: '#083782', // rgb(8, 55, 130) original icon color
    light: '#395F9B', // rgb(57, 95, 155)
    dark: '#05265B', // rgb(5, 38, 91)

    /*
     * Color palettes
     *
     * 基本カラーをベースにパレットを自動生成する。
     * https://material.io/inline-tools/color/
     */
    900: '#00266c',
    800: '#083782', // original icon color
    700: '#14408e',
    600: '#204a9a',
    500: '#2752a3',
    400: '#4d6aaf',
    300: '#6e85bc',
    200: '#97a7d0',
    100: '#c0c9e3',
    50: '#e6eaf3',
  },

  /** コントラストテキスト */
  contrastText: {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255, 255, 255, 0.54)',
    disabled: 'rgba(255, 255, 255, 0.38)',
  },

  /** 選択カラー */
  select: {
    row: 'rgba(240, 84, 124, 0.08)',
  },

  /** 性別カラー */
  gender: {
    male: '#8cd8fc',
    female: '#fc8cc8',
    none: '#e0e0e0',
  },

  success: {
    main: '#4caf50',
    900: '#1b5e1f',
    800: '#2e7d31',
    700: '#388e3b', // dark
    600: '#43a046',
    500: '#4caf4f', // main
    400: '#66bb69',
    300: '#81c784', // light
    200: '#a5d6a7',
    100: '#c8e6c9',
    50: '#e8f5e9',
  },

  info: {
    main: '#2196f3',
    900: '#1045a1',
    800: '#1764c0',
    700: '#1a75d2', // dark
    600: '#1f87e5',
    500: '#2194f3', // main
    400: '#42a4f5',
    300: '#63b4f6', // light
    200: '#90c9f9',
    100: '#bbdefb',
    50: '#e3f2fd',
  },

  warning: {
    main: '#ff9800',
    900: '#e65200',
    800: '#ef6d00',
    700: '#f57d00', // dark
    600: '#fb8d00',
    500: '#ff9900', // main
    400: '#ffa826',
    300: '#ffb84d', // light
    200: '#ffcd80',
    100: '#ffe0b2',
    50: '#fff3e0',
  },

  error: {
    main: '#F44336',
    900: '#b71b1c',
    800: '#c62828',
    700: '#d32f2f', // dark
    600: '#e53935',
    500: '#f44336', // main
    400: '#ef5350',
    300: '#e57373', // light
    200: '#ef9a9a',
    100: '#ffcdd2',
    50: '#ffebee',
  },
}
