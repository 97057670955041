import { CheckupEvent } from '../../../interfaces/healthCare/checkup'
import { ApiDataResponse, ApiResponse } from '../../../interfaces/api'
import { api, apiVersion } from '../../base'

const common = 'checkup/events'

export type EventsData = {
  events: CheckupEvent[]
}
export type EventData = {
  event: CheckupEvent
}

export type EventsParams = {
  page?: number
}

export type EventParams = Pick<
  CheckupEvent,
  'name' | 'typeId' | 'startAt' | 'endAt' | 'fiscalYearId' | 'userId'
>

type EventsResponse = ApiDataResponse<EventsData>
type EventResponse = ApiDataResponse<EventData>

export const getEvents = (
  fiscalYearId: string | number,
  params?: EventsParams,
): Promise<EventsResponse> => {
  return api.get(`${apiVersion}/${common}/${fiscalYearId}/list`, params)
}

export const getEvent = (eventId: string): Promise<EventResponse> => {
  return api.get(`${apiVersion}/${common}/${eventId}`)
}

export const postEvent = (params: EventParams): Promise<EventResponse> => {
  return api.post(`${apiVersion}/${common}`, params)
}

export const patchEvent = (
  eventId: string,
  params: EventParams,
): Promise<EventResponse> => {
  return api.patch(`${apiVersion}/${common}/${eventId}`, params)
}

export const deleteEvent = (eventId: string): Promise<ApiResponse> => {
  return api.delete(`${apiVersion}/${common}/${eventId}`)
}
