import { api, apiVersion } from './base'
import { UserRole } from '../interfaces/userRole'
import { ApiDataResponse } from '../interfaces/api'

export type UserRolesData = {
  userRoles: UserRole[]
}

type UserRolesResponse = ApiDataResponse<UserRolesData>

/**
 * ユーザー毎の権限一覧
 * /user_roles#index
 */
export const ApiUserRoles = (): Promise<UserRolesResponse> => {
  const url = `${apiVersion}/user_roles`
  return api.get(url)
}
