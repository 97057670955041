import { useEffect } from 'react'
import * as React from 'react'
import { useSnackbar } from 'notistack'
import { actions } from '../../modules/snackbar'
import { useAppDispatch, useAppSelector } from '../../hooks'

export const Snackbar: React.FC = () => {
  const { snackbars } = useAppSelector((state) => state.snackbar)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  useEffect(() => {
    snackbars.forEach(({ key, message, options = {} }) => {
      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (event, key) => {
          dispatch(actions.remove(key))
        },
      })
    })
  }, [snackbars, enqueueSnackbar, closeSnackbar, dispatch])

  return null
}
