import { createTheme } from '@mui/material/styles'
import { colors } from './colors'

export const theme = createTheme({
  typography: {
    fontSize: 13,
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),
  },
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    background: {
      default: '#fafafa',
    },
  },
  spacing: 4,
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        text: {
          border: 0,
          padding: '0 10px',
          textDecoration: 'none',
        },
        outlinedPrimary: {
          borderColor: colors.primary.main,
        },
        outlinedSecondary: {
          borderColor: colors.secondary.main,
        },
        outlinedInherit: {
          borderColor: 'rgba(0, 0, 0, 0.38)',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$focused': { color: colors.primary.main },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.8125rem',
          lineHeight: 1.43,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 140,
          borderRadius: 4,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 40,
    },
  },
})
