import { ReactNode } from 'react'
import * as React from 'react'
import { Dialog, DialogTitle, styled } from '@mui/material'
import { Operation, OperationType } from '../../interfaces/operation'
import { CloseIconButton } from '../IconButton/CloseIconButton'

export const dialogLabel = (
  base: string,
  type: Operation,
): { title: string; primary: string } => {
  switch (type) {
    case OperationType.Edit:
      return { title: base + '編集', primary: '更新' }
    case OperationType.Create:
    default:
      return { title: base + '作成', primary: '作成' }
  }
}

export type CloseButtonDialogProps = {
  className?: string
  open: boolean
  title?: string
  titleStartIcon?: ReactNode
  titleEndIcon?: ReactNode
  hideTitleStartIcon?: boolean
  hideTitleEndIcon?: boolean
  onClose: () => void
  paperClass?: string
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  children: ReactNode
}

export const CloseButtonDialog: React.FC<CloseButtonDialogProps> = ({
  open,
  title = 'タイトル',
  onClose,
  disableBackdropClick = true,
  ...props
}) => (
  <Dialog
    open={open}
    onClose={(_, reason) => {
      if (disableBackdropClick) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose()
        }
      } else {
        onClose()
      }
    }}
    className={props.className}
    classes={{ paper: props.paperClass }}
    aria-labelledby="form-dialog-title"
    disableEscapeKeyDown={props.disableEscapeKeyDown}
    maxWidth={props.maxWidth}
    fullWidth
  >
    <DialogTitle id="form-dialog-title">
      <TitleContainer>
        {!props.hideTitleStartIcon && props.titleStartIcon}
        <span>{title}</span>
        {!props.hideTitleEndIcon && props.titleEndIcon}
      </TitleContainer>
      <CloseIconButton onClose={onClose} />
    </DialogTitle>
    {props.children}
  </Dialog>
)

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
