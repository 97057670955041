import { configureStore } from '@reduxjs/toolkit'
import authReducer from './modules/auth'
import snackbarReducer from './modules/snackbar'
import dashboardReducer from './modules/dashboard'
import wellnessLiteReducer from './modules/wellnessLite'
import karteReducer from './modules/karte'
import { checkupReducer } from './modules/healthCare/checkup'
import employeeReducer from './modules/employee'
import branchReducer from './modules/branch'
import departmenthReducer from './modules/department'
import settingReducer from './modules/setting'
import naviReducer from './modules/navi'
import personnelReportReducer from './modules/shareNavi/personnelReport'
import stressCheckReducer from './modules/healthCare/stressCheck'
import overworkReducer from './modules/healthCare/overwork'
import interviewReducer from './modules/shareNavi/interview'
import noticeReducer from './modules/notice'
import documentReducer from './modules/document'
import userReducer from './modules/user'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    dashboard: dashboardReducer,
    wellnessLite: wellnessLiteReducer,
    navi: naviReducer,
    karte: karteReducer,
    checkup: checkupReducer,
    employee: employeeReducer,
    branch: branchReducer,
    department: departmenthReducer,
    setting: settingReducer,
    personnelReport: personnelReportReducer,
    stressCheck: stressCheckReducer,
    overwork: overworkReducer,
    interview: interviewReducer,
    notice: noticeReducer,
    document: documentReducer,
    user: userReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
