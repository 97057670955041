/**
 * メニュー
 */
export const menu = {
  /** ダッシュボード */
  DASHBOARD: 1,
  /** 従業員カルテ */
  KARTE: 2,
  /** 不調者対応ナビ */
  SICK: 3,
  /** 早期発見ナビ */
  DETECTION: 4,
  /** 衛生管理ナビ */
  HEALTH_CARE: 5,
  /** 設定 */
  SETTING: 6,
  /** アクセスログ */
  ACCESS_LOG: 7,
  /** お知らせ */
  INFORMATION: 8,
  /** 健康診断 */
  CHECKUP: 9,
  /** 残業時間 */
  OVERTIME: 10,
  /** ストレスチェック */
  STRESS_CHECK: 11,
  /** 情報共有ナビ */
  SHARE_NAVI: 12,
  /** 情報共有ナビ(マネージャー) */
  SHARE_NAVI_MANAGER: 13,
  /** 衛生管理ナビ(検診運用サポート) */
  HEALTH_CARE_SUPPORT: 14,
  /** 情報共有ナビ(産業保健スタッフ) */
  SHARE_NAVI_STAFF: 15,
} as const
