import { api, ApiUnAuthJson, apiVersion, FetchExecute } from './base'
import {
  ApiDataResponse,
  ApiLoginDataResponse,
  ApiResponse,
} from '../interfaces/api'
import { SessionUserLockedStatus, SessionUser } from '../interfaces/user'
import { Company } from '../interfaces/setting'

export type LoginParams = {
  email: string
  password: string
}

type LogoutParams = {
  email: string
}

type SessionUserLockedStatusData = {
  loginLock: SessionUserLockedStatus
}

type CompanyResponse = ApiDataResponse<Company>
type ResetPasswordTokenConfirmResponse = ApiDataResponse<{ code: string }>
export type LoginResponse = ApiLoginDataResponse<SessionUser>
type LoginLockResponse = ApiDataResponse<SessionUserLockedStatusData>

/**
 * X1-101 / 会社情報管理
 * /settings/companies#Index
 */
export const ApiCompany = (): Promise<CompanyResponse> => {
  const url = `${apiVersion}/settings/companies`
  return api.get(url)
}

/**
 * 001 / ログイン
 * /login
 */
export const ApiLogin = (params: LoginParams): Promise<LoginResponse> => {
  const url = `${apiVersion}/login`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}

/**
 * 002 / ログアウト
 * /logout
 */
export const ApiLogout = (params: LogoutParams): Promise<ApiResponse> => {
  const url = `${apiVersion}/logout`
  return api.delete(url, params)
}

/**
 * 003 / パスワードリセット用のメール送信処理
 * /forgot_password
 */
export const ApiForgotPassword = (
  params: Record<string, unknown>,
): Promise<ApiResponse> => {
  const url = `${apiVersion}/forgot_password`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}

/**
 * 004 / パスワードリセット用のトークン確認
 * /reset_password_token_confirm
 */
export const ApiResetPasswordTokenConfirm = (
  params: Record<string, unknown>,
): Promise<ResetPasswordTokenConfirmResponse> => {
  const url = `${apiVersion}/reset_password_token_confirm`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}

/**
 * 005 / パスワードリセットを実行する
 * /reset_password
 */
export const ApiResetPassword = (
  params: Record<string, unknown>,
): Promise<ApiResponse> => {
  const url = `${apiVersion}/reset_password`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}

/**
 * /ログインロックの登録
 * /login_lock
 */
export const ApiLoginLock = (
  params: Record<string, unknown>,
): Promise<LoginLockResponse> => {
  const url = `${apiVersion}/login_lock`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}
