import * as React from 'react'
import { Typography, Link } from '@mui/material'

export const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.elixia.co.jp/" underline="hover">
        ELIXIA Co., Ltd.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
