import clsx, { ClassValue } from 'clsx'
import emojiRegex from 'emoji-regex'

/**
 * 文字列の判定
 * 文字列であるかを判定する。
 *
 * @param value
 */
export const isString = (value: unknown): value is string =>
  typeof value === 'string' || value instanceof String

/**
 * 空白文字の除去
 *
 * 引数に指定した文字列の中のすべての空白文字を除去する。
 * 空白文字とは半角空白、全角空白、タブ、改行を含む。
 *
 * @param str 除去対象文字列
 * @see String.trim
 */
export const trimAll = (str: string | null | undefined): string => {
  if (str === null || str === undefined) {
    return ''
  }
  return str.replace(/\s+/g, '')
}
/**
 * 文字数の省略
 *
 * 文字列が指定した文字数を超えた場合に、超過分を省略記号に変換して返却する。
 * 指定する文字数は返却する文字列が省略記号を含めた数になる。
 *
 * @example
 * ellipsis('12345', 5) // => '12345'
 * ellipsis('1234567890', 6) // => '123...' (6は省略記号も含めた文字数)
 *
 * @param str 判定文字列
 * @param size 文字数
 * @param mark 省略記号
 * @returns 変換後文字列
 */
export const ellipsis = (str: string, size: number, mark = '...'): string => {
  /* もしサイズが 0 ならそのまま返却 */
  if (size <= 0) {
    return str
  }
  return str.length > size ? str.substring(0, size - mark.length) + mark : str
}

export const ellipsisFileName = (fileName: string, size = 25, mark = '...') => {
  const lastIndexOf = fileName.lastIndexOf('.')
  if (lastIndexOf === -1) {
    return ellipsis(fileName, size, mark)
  } else {
    const ext = fileName.substring(lastIndexOf + 1, fileName.length)
    return ellipsis(fileName, size, `${mark}.${ext}`)
  }
}

export const setNullToChar = (
  value: number | string | undefined | null,
  char = '',
): number | string => {
  return value === null || value === undefined ? char : value
}

export const isValidEmail = (str: string): boolean => {
  const regex = /^[\w.!#$%&'*+/=?^`{|}~-]+@[a-z\d-]+(?:\.[a-z\d-]+)*$/i
  return regex.test(str)
}

export const isValidHalfWidthAlphanumeric = (str: string): boolean => {
  return /^[A-Z\d]+$/i.test(str)
}
/**
 * 数値の判定
 *
 * 引数に指定した文字列が、数値(整数、小数)であるかを判定する。
 * ただし、符号はつかないこと。
 *
 * @param str 判定対象文字列
 */
export const isValidNumber = (str: string): boolean => {
  return /^(?:[1-9]\d*|0)(?:\.\d+)?$/.test(str)
}

/**
 * 整数値の判定
 *
 * 引数に指定した文字列が、整数値であるかを判定する。
 * ただし、符号はつかないこと。
 *
 * @param value 判定対象文字列
 */
export const isInteger = (value: string) => /^\d+$/.test(value)

/**
 * 絵文字の判定
 *
 * 引数に指定した文字が、絵文字か絵文字を含む文字列であるかを判定する。
 *
 * @param value 判定文字列
 */
export const hasEmoji = (value: string | null | undefined): boolean => {
  if (!value) {
    return false
  }
  const regx = emojiRegex()
  return regx.test(value)
}

export const setStringParams = (
  message: string,
  params: Record<string, string | number>,
): string => {
  if (!message) {
    return message
  }

  let result = message
  for (const [key, value] of Object.entries(params)) {
    result = result.replace(`:${key}`, value + '')
  }
  return result
}

export type URLParams = Record<
  string,
  string | number | number[] | string[] | null | undefined
>

/**
 * URLパラメータの取得
 *
 * 引数に設定したパラメータオブジェクトから
 * URLパラメータ文字列を返却する。
 * 値に配列が含まれている場合にはパラメータ名に'[]'を付加して
 * 複数列挙する。
 * オブジェクトが空の場合には空文字を返却する。
 *
 * @example
 * const params = {
 *   name: 'tom',
 *   age: null,
 *   branchId: '1',
 *   depIds: [3,4]
 * }
 * const url = getURLParams(params) // => '?name=tom&branchId=1&depIds[]=3&depIds[]=4'
 */
export const getURLParams = (params: URLParams | null | undefined): string => {
  if (!params) {
    return ''
  }

  const array = encodeURIComponent('[]')
  let searchParams = Object.entries(params)
    .filter(
      ([, value]) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0),
    )
    .map(([key, value], index) => {
      const and = index > 0 ? '&' : ''
      if (Array.isArray(value)) {
        return (
          and +
          value
            .map((val, index) => {
              const and = index > 0 ? '&' : ''
              return `${and}${key}${array}=${val}`
            })
            .join('')
        )
      } else if (value !== null && value !== undefined) {
        return `${and}${key}=${encodeURIComponent(value)}`
      }
    })
    .join('')

  if (searchParams) {
    searchParams = '?' + searchParams
  }

  return searchParams
}

/**
 * URLパラメータの変換
 *
 * 引数に指定したクエリパラメータをオブジェクトに変換する。
 * プロパティ名に[]を指定した項目は配列として格納する。
 *
 * @example
 * const result = parseURLParams('?name=tom&itemIds%5B%5D=1&itemIds%5B%5D=2')
 * // result = { name: 'tom', itemIds: [1, 2] }
 */
export const parseURLParams = (
  params: string,
): Record<string, string | string[]> => {
  let result: Record<string, string | string[]> = {}

  if (!params) {
    return result
  }

  if (params.indexOf('?') === 0) {
    params = params.slice(1)
  }

  const pairs = params.split('&')

  pairs.forEach((pair) => {
    const index = pair.indexOf('=')

    if (-1 < index) {
      let key = decode(pair.slice(0, index))
      const val = decode(pair.slice(index + 1))

      if (key.endsWith('[]')) {
        key = key.replace('[]', '')
        if (key in result) {
          result = {
            ...result,
            [key]: [...(result[key] as string[]), val],
          }
        } else {
          result = { ...result, [key]: [val] }
        }
      } else {
        result = { ...result, [key]: val }
      }
    } else {
      if (pair) {
        result = {
          ...result,
          [pair]: '',
        }
      }
    }
  })

  return result
}

const decode = (str: string) => {
  return str.replace(/[ +]/g, '%20').replace(/(?:%[a-f\d]{2})+/gi, (match) => {
    return decodeURIComponent(match)
  })
}

/**
 * クエリラパメータの解析
 *
 * 引数に指定したクエリ文字列をパラメータオブジェクトに変換する。
 * parseURLParams と比較して以下のような違いがある。
 *
 * <ul>
 *   <li>配列の値は数値に変換する</li>
 *   <li>その他の値は文字列だが、指定した特定の値のみ数値にする</li>
 *   <li>デフォルトで 'page' プロパティは数値にする</li>
 * </ul>
 *
 * @param params パラメータオブジェクト
 * @param search クエリ文字列
 * @param parseIntItems 数値変換対象項目
 * @see parseURLParams
 */
export const parseQueryParams = <T extends Record<string, unknown>>(
  params: T,
  search: string,
  parseIntItems: string[] = [],
): T => {
  const queries = parseURLParams(search)
  const parses = [...parseIntItems, 'page']

  for (const [key, values] of Object.entries(queries)) {
    const param = Array.isArray(values)
      ? values.map((value) => Number.parseInt(value))
      : parses.includes(key)
        ? Number.parseInt(values)
        : values
    params = {
      ...params,
      [key]: param,
    }
  }
  return params
}

/**
 * クラスの結合
 * @param classes クラス文字列
 */
export const joinClasses = (...classes: ClassValue[]): string => clsx(classes)
