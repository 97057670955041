import { EmployeeStatusCount } from '../interfaces/dashboard'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  employeeStatusCounts: EmployeeStatusCount[] | null
}

const initialState: State = {
  employeeStatusCounts: null,
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setEmployeeStatusCount: (
      state,
      action: PayloadAction<EmployeeStatusCount[]>,
    ) => {
      state.employeeStatusCounts = action.payload
    },
  },
})

export const actions = dashboard.actions
export default dashboard.reducer
