import { ValidateError } from '../interfaces/validation'

// Login-Email
export const isLoginEmail = (text: string): boolean => {
  const reg = new RegExp(/^[\w.%+-]+@[a-z\d.-]+\.[a-z]{2,}$/i).test(text)
  if (reg && isCharactersNum(text, 50, 'max')) {
    return true
  } else {
    return false
  }
}

// 指定文字数
export const isCharactersNum = (
  text: string,
  num: number,
  limit: string,
): boolean => {
  const chkNuk = text.length
  if (limit === 'min') {
    if (chkNuk < num) {
      return false
    }
  }
  if (limit === 'max') {
    if (chkNuk > num) {
      return false
    }
  }
  return true
}

export const isEmptyValue = (
  value: string | number | number[] | null | undefined,
): boolean => {
  return value === '' || value === null || value === undefined
}

export class ValidateErrorControl<T> {
  private errors: ValidateError<T>[]

  constructor(errors: ValidateError<T>[]) {
    this.errors = errors
  }

  add(validateError: ValidateError<T>): void {
    const result = this.find(validateError.item)
    if (!result) {
      this.errors.push(validateError)
    } else {
      this.errors = this.errors.map((error) => {
        return error.item === validateError.item ? validateError : error
      })
    }
  }

  remove(item: string): void {
    this.errors = this.errors.filter((error) => error.item !== item)
  }

  removes(...items: (keyof T)[]): void {
    this.errors = this.errors.filter((error) => !items.includes(error.item))
  }

  has(item: string): boolean {
    return this.errors.some((error) => error.item === item)
  }

  find(item: keyof T): ValidateError<T> | undefined {
    return this.errors.find((error) => error.item === item)
  }

  message(item: keyof T): string {
    const result = this.find(item)
    return result ? result.message : ''
  }

  exist(): boolean {
    return this.errors.length > 0
  }

  clear(): void {
    this.errors = []
  }

  values(): ValidateError<T>[] {
    return this.errors
  }
}
