export * from './interviewsSlice'
export * from './interviewSlice'
export * from './interviewReasonsSlice'
export * from './interviewWaysSlice'
export * from './interviewReferencesSlice'
export * from './interviewTemplatesSlice'
export * from './interviewHistoriesSlice'

import { combineReducers } from '@reduxjs/toolkit'
import interviewsReducer from './interviewsSlice'
import interviewReducer from './interviewSlice'
import interviewReasonsReducer from './interviewReasonsSlice'
import interviewWaysReducer from './interviewWaysSlice'
import interviewReferencesReducer from './interviewReferencesSlice'
import interviewTemplatesReducer from './interviewTemplatesSlice'
import interviewHistoriesReducer from './interviewHistoriesSlice'

const reducer = combineReducers({
  interviews: interviewsReducer,
  interview: interviewReducer,
  interviewReasons: interviewReasonsReducer,
  interviewWays: interviewWaysReducer,
  interviewReferences: interviewReferencesReducer,
  interviewTemplates: interviewTemplatesReducer,
  interviewHistories: interviewHistoriesReducer,
})

export default reducer
