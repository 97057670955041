import * as React from 'react'
import { DefaultButton } from './DefaultButton'
import ClearIcon from '@mui/icons-material/Clear'

type Props = {
  onClick: () => void
  disabled?: boolean
  className?: string
  showIcon?: boolean
  cancelLabel?: string
}

export const CancelButton = ({
  disabled = false,
  onClick,
  className,
  showIcon = false,
  cancelLabel = 'キャンセル',
}: Props) => {
  const startIcon: React.ReactNode = showIcon ? <ClearIcon /> : null

  return (
    <DefaultButton
      onClick={onClick}
      disabled={disabled}
      className={className}
      startIcon={startIcon}
    >
      {cancelLabel}
    </DefaultButton>
  )
}
