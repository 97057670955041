import { ApiDataResponse, ApiResponse } from '../interfaces/api'

export const checkResponseDataStatus = <T>(response: ApiDataResponse<T>): T => {
  if (response.status === 'success') {
    return response.data
  } else {
    throw new Error(response.message)
  }
}

export const checkResponseStatus = <T extends ApiResponse>(response: T): T => {
  if (response.status === 'success') {
    return response
  } else {
    throw new Error(response.message)
  }
}

export const getObjectUrl = (blob: Blob) =>
  URL.createObjectURL(new Blob([blob]))

export const downloadUrl = (url: string, fileName?: string) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName || 'download.csv')
  document.body.appendChild(link)
  link.click()
}

export const downloadFile = (response: Blob, fileName?: string): void => {
  const url = getObjectUrl(response)
  downloadUrl(url, fileName)
}
