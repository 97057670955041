import * as React from 'react'
import { DefaultButton, DefaultButtonProps } from './DefaultButton'

export type Props = DefaultButtonProps

export const PrimaryButton: React.FC<Props> = ({
  variant = 'contained',
  color = 'primary',
  ...props
}) => {
  return <DefaultButton {...props} variant={variant} color={color} />
}
