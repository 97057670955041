import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'

export type DefaultButtonProps = ButtonProps

export const DefaultButton: FC<DefaultButtonProps> = ({
  disabled = false,
  color = 'inherit',
  variant = 'outlined',
  ...props
}) => {
  return (
    <Button variant={variant} color={color} disabled={disabled} {...props} />
  )
}
