import { ApiUnAuthJson, apiVersion, FetchExecute } from './base'
import { ApiDataResponse } from '../interfaces/api'
import { SessionOtp } from '../interfaces/user'

type SessionOtpData = {
  otp: SessionOtp
}

type OtpResponse = ApiDataResponse<SessionOtpData>

/**
 * /2FA認証登録
 * /otp_new
 */
export const ApiOtpNew = (
  params: Record<string, unknown>,
): Promise<OtpResponse> => {
  const url = `${apiVersion}/otp/new`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}

/**
 * /2FA認証 コード認証
 * /otp_create
 */
export const ApiOtpCreate = (
  params: Record<string, unknown>,
): Promise<OtpResponse> => {
  const url = `${apiVersion}/otp/create`
  return FetchExecute(url, ApiUnAuthJson('POST', params))
}
