import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { AppProvider } from './providers'

Sentry.init({
  dsn: process.env.REACT_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.REACT_SENTRY_ENV,
  tracesSampleRate: 1.0,
})

const container = document.body.appendChild(document.createElement('div'))
const root = createRoot(container)
root.render(<AppProvider />)
