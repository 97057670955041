import { AppDispatch } from '../../store'
import {
  deleteEmployee,
  EmployeesParams,
  getEmployee,
  getEmployees,
  postEmployee,
  putEmployee,
  importEmployeeResults,
  exportEmployeeResults,
} from '../../apis/setting'
import { actions } from '../../modules/employee'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../snackbars'
import {
  checkResponseDataStatus,
  checkResponseStatus,
  downloadFile,
} from '../api'
import { Employee } from '../../interfaces/employee'
import { CSVValidateRecord } from '../csvUpload'

export const fetchEmployees =
  (params?: EmployeesParams) => (dispatch: AppDispatch) => {
    dispatch(actions.setWaitingEmployees())
    return getEmployees(params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setEmployees(response.data))
        dispatch(
          actions.setPaginationEmployees({ pagination: response.meta ?? null }),
        )
        return {
          employees: response.data.employees,
          pagination: response.meta ?? null,
        }
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchEmployee =
  (employeeId: number | string) => (dispatch: AppDispatch) => {
    dispatch(actions.setWaitingEmployee())
    return getEmployee(employeeId)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setEmployee(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const createEmployee =
  (employee: Employee, callback?: () => void) => (dispatch: AppDispatch) => {
    /* プロパティから id を除去 */
    const params = (({ id: _id, ...rest }) => rest)(employee)
    return postEmployee(params)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('従業員情報を登録しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateEmployee =
  (employee: Employee, callback?: () => void) => (dispatch: AppDispatch) =>
    putEmployee(employee)
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('従業員情報を更新しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const removeEmployee =
  (employeeId: number, callback?: () => void) => (dispatch: AppDispatch) =>
    deleteEmployee({ id: employeeId })
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('従業員情報を削除しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const resetEmployees =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetEmployees())
  }

export const resetEmployee =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetEmployee())
  }

export const uploadResults =
  (params: { file: File }, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingUploadCsv())
    return importEmployeeResults(params)
      .then((response) => {
        if (response.status === 'error') {
          response.errors &&
            dispatch(
              actions.setErrorResponseResults({ errors: response.errors }),
            )
          throw new Error(response.message)
        } else {
          dispatch(enqueueSnackbar(`${response.message}`))
          return response.message
        }
      })
      .then(() => callback && callback())
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingUploadCsv())
      })
  }

export const downloadResults =
  (fileName?: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingDownloadCsv())
    return exportEmployeeResults()
      .then((response) => downloadFile(response, fileName))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingDownloadCsv())
      })
  }

export const resetErrorResponseResult =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetErrorResponseResults())
  }

type EmployeeRecordPick = Pick<
  Employee,
  | 'branchId'
  | 'departmentId'
  | 'code'
  | 'name'
  | 'email'
  | 'employeeTitleId'
  | 'birthday'
  | 'gender'
  | 'employeeStatusId'
  | 'statusFrom'
  | 'statusTo'
>

type ResultRecord = { [P in keyof EmployeeRecordPick]: string }

export const resultsCsvHeaders: CSVValidateRecord<ResultRecord>[] = [
  { index: 0, name: 'branchId', label: '事業所ID', nullable: true },
  { index: 1, name: 'departmentId', label: '部署ID', nullable: true },
  { index: 2, name: 'code', label: '従業員ID' },
  { index: 3, name: 'name', label: '従業員名' },
  { index: 4, name: 'email', label: 'メールアドレス', nullable: true },
  { index: 5, name: 'employeeTitleId', label: '職位', nullable: true },
  { index: 6, name: 'birthday', label: '生年月日', nullable: true },
  { index: 7, name: 'gender', label: '性別' },
  {
    index: 8,
    name: 'employeeStatusId',
    label: '就業ステータス',
    nullable: true,
  },
  { index: 9, name: 'statusFrom', label: 'ステータス/from', nullable: true },
  { index: 10, name: 'statusTo', label: 'ステータス/to', nullable: true },
]
