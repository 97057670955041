import { AppDispatch, store } from '../../../store'
import {
  ClinicData,
  ClinicParams,
  ClinicsParams,
  deleteClinic,
  exportClinics,
  getClinic,
  getClinics,
  importClinics,
  patchClinic,
  postClinic,
} from '../../../apis/healthCare/setting/clinic'
import { actions } from '../../../modules/healthCare/checkup'
import { Prop } from '../../../interfaces/entity'
import { ValidateError } from '../../../interfaces/validation'
import { validateErrorControl } from '../../validations'
import { isValidHalfWidthAlphanumeric } from '../../../utils/strings'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../../snackbars'
import { CSVValidateRecord } from '../../csvUpload'
import { Clinic } from '../../../interfaces/healthCare/checkup'
import {
  checkResponseDataStatus,
  checkResponseStatus,
  downloadFile,
} from '../../api'

export const fetchClinics =
  (params?: ClinicsParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingClinics())
    return getClinics(params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setClinics(response.data))
        dispatch(
          actions.setPaginationClinics({ pagination: response.meta ?? null }),
        )
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingClinics())
      })
  }

export const fetchClinic =
  (clinicId: number | undefined) =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: ClinicData; type: string } | void> => {
    if (clinicId === undefined) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(clinicId)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingClinics())
    return getClinic(clinicId)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.resetWaitingClinics())
        return dispatch(actions.setClinic(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
        dispatch(actions.resetWaitingClinics())
      })
  }

export const createClinic =
  (params: ClinicParams | null, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (params === null) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(ClinicParams)'))
      return Promise.resolve()
    }
    const errors = validateAll(params)
    if (errors.length) {
      dispatch(actions.setValidateClinicErrors(errors))
      return Promise.resolve()
    }

    dispatch(actions.setWaitingClinics())
    return postClinic(params)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setClinic(data)))
      .then(() => dispatch(enqueueSnackbar('医療機関の登録が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingClinics())
      })
  }

export const changeClinic =
  (key: keyof ClinicParams, value: string | number) =>
  (dispatch: AppDispatch): { type: string } => {
    const holdErrors = store.getState().checkup.validateClinicErrors
    const changeErrors = validate({ key, value }, holdErrors)
    dispatch(actions.setValidateClinicErrors(changeErrors))
    return dispatch(actions.changeClinic({ key, value }))
  }

export const updateClinic =
  (
    clinicId: number | undefined,
    params: ClinicParams | null,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (clinicId === undefined || params === null) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(updateClinic)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingClinics())
    return patchClinic(clinicId, params)
      .then(checkResponseDataStatus)
      .then((data) => dispatch(actions.setClinic(data)))
      .then(() => dispatch(enqueueSnackbar('医療機関の更新が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingClinics())
      })
  }

export const removeClinic =
  (clinicId: number | undefined, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (clinicId === undefined) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(clinicId)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingClinics())
    return deleteClinic(clinicId)
      .then(checkResponseStatus)
      .then(() => dispatch(enqueueSnackbar('医療機関の削除が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingClinics())
      })
  }

export const uploadClinics =
  (params: { file: File }, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingClinics())
    return importClinics(params)
      .then((response) => {
        if (response.status === 'error') {
          response.errors &&
            dispatch(
              actions.setErrorResponseClinics({ errors: response.errors }),
            )
          throw new Error(response.message)
        } else {
          dispatch(enqueueSnackbar(`${response.message}`))
          return response.message
        }
      })
      .then(() => {
        dispatch(actions.resetWaitingClinics())
        callback && callback()
      })
      .catch((reason) => {
        dispatch(actions.resetWaitingClinics())
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const downloadClinics =
  (fileName?: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingClinics())
    return exportClinics()
      .then((response) => downloadFile(response, fileName))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingClinics())
      })
  }

export const resetClinic =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetClinic())
    dispatch(resetValidateErrors())
    dispatch(actions.resetErrorResponseClinics())
  }

const resetValidateErrors =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetValidateClinicErrors())
  }

const validateAll = (clinic: ClinicParams): ValidateError<ClinicParams>[] => {
  const props: (keyof ClinicParams)[] = ['code', 'name', 'address']
  let errors = store.getState().checkup.validateClinicErrors
  props.forEach((key) => {
    errors = validate({ key, value: clinic && clinic[key] }, errors)
  })
  return errors
}

const validate = (
  prop: Prop<ClinicParams>,
  errors: ValidateError<ClinicParams>[],
): ValidateError<ClinicParams>[] => {
  const validateErrors = validateErrorControl<ClinicParams>(errors)
  validateErrors.remove(prop.key)

  if (prop.value === '' || prop.value === null) {
    validateErrors.add({
      item: prop.key,
      message: `:${prop.key}は必ず入力してください`,
    })
    return validateErrors.values()
  }

  switch (prop.key) {
    case 'code':
      if (!isValidHalfWidthAlphanumeric(prop.value.toString())) {
        validateErrors.add({
          item: prop.key,
          message: `:${prop.key}は半角英数字を入力してください`,
        })
      }
      break
    case 'name':
    case 'address':
      break
  }
  return validateErrors.values()
}

export const getClinicName = (clinicId: number | undefined): string => {
  if (clinicId === undefined) {
    return ''
  }
  const { resultClinics } = store.getState().checkup
  return resultClinics?.find((clinic) => clinic.id === clinicId)?.name ?? ''
}

type ClinicRecord = Pick<Clinic, 'code' | 'name' | 'address'>

// prettier-ignore
export const clinicsCsvHeaders: CSVValidateRecord<ClinicRecord>[] = [
  {index: 0, name: 'code', label: '医療機関ID', maxLength: 8, alphaNumeric: true},
  {index: 1, name: 'name', label: '医療機関名', maxLength: 50},
  {index: 2, name: 'address', label: '所在地', maxLength: 100},
]
