import {
  ApiLogin,
  ApiLogout,
  LoginParams,
  LoginResponse,
} from '../../apis/session'
import { AppDispatch } from '../../store'
import { checkResponseDataStatus, checkResponseStatus } from '../api'
import { enqueueErrorSnackbar } from '../snackbars'
import { actions } from '../../modules/auth'
import { getUser, putUser, UserParams } from '../../apis/setting'
import { getUserId, removeAll } from '../../utils/userStorage'
import { resetStore } from './userRole'
import * as Sentry from '@sentry/react'

type LoginOption = Partial<{
  onSuccess: (response: LoginResponse) => void
  onError: (response: LoginResponse) => void
}>

export const login =
  (params: LoginParams, option?: LoginOption) =>
  (dispatch: AppDispatch): Promise<LoginResponse | void> => {
    return ApiLogin(params)
      .then((response) => {
        if (response.status === 'success') {
          dispatch(actions.setUser(response.data))
          option?.onSuccess && option.onSuccess(response)
        } else if (response.status === 'error') {
          option?.onError && option.onError(response)
        }
        return response
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchAuthedUser =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    const userId = getUserId()
    return userId ? dispatch(fetchUser(userId)) : Promise.resolve()
  }

const fetchUser =
  (userId: number) =>
  (dispatch: AppDispatch): Promise<void> => {
    return getUser(userId)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setUser(data))
        Sentry.setUser({ id: userId.toString() })
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateUser =
  (params: UserParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    return putUser(params)
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setUser(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const logout =
  (email: string, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    return ApiLogout({ email })
      .then(checkResponseStatus)
      .then(() => {
        /* store削除 */
        dispatch(resetStore())
        /* storage削除 */
        removeAll()
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }
