import { api, apiVersion } from '../base'
import { Branch } from '../../interfaces/setting'
import {
  ApiDataResponse,
  ApiImportResponse,
  ApiResponse,
} from '../../interfaces/api'

export type BranchesParams = {
  page?: number
}

export type BranchParams = Pick<Branch, 'code' | 'name'>

export type BranchesData = {
  branches: Branch[]
}

export type BranchUpdateParams = BranchParams & Pick<Branch, 'id'>

type BranchesResponse = ApiDataResponse<BranchesData>
type BranchResponse = ApiDataResponse<Branch>
const common = `${apiVersion}/settings/branches`

export const getBranches = (): Promise<BranchesResponse> =>
  api.get(`${apiVersion}/settings/branches`)

export const getBranchList = (params?: BranchesParams) =>
  api.get<BranchesResponse>(`${common}/list`, params)

export const getBranch = (branchId: number | string) =>
  api.get<BranchResponse>(`${common}/${branchId}`)

export const postBranch = (params: BranchParams): Promise<BranchResponse> =>
  api.post(`${common}/create`, params)

export const putBranch = (
  params: BranchUpdateParams,
): Promise<BranchResponse> => api.put(`${common}/update`, params)

export const deleteBranch = (params: { id: number }): Promise<ApiResponse> =>
  api.delete(`${common}/delete`, params)

export const importResults = (params: {
  file: File
}): Promise<ApiImportResponse> => {
  const formData = new FormData()
  formData.append('file', params.file)
  const url = `${common}/setting_csv_import`
  return api.multiPart(url, formData)
}
export const exportBranchResults = (): Promise<Blob> => {
  const url = `${common}/setting_csv_export`
  return api.download(url)
}

export const getStressCheckBranchOptions = (
  stressCheckId: number | string,
): Promise<BranchesResponse> =>
  api.get(
    `${apiVersion}/health_care/stress_checks/${stressCheckId}/branches_index`,
  )
