import { AppDispatch, store } from '../../store'
import { actions } from '../../modules/setting'
import { getEmployeeTitles } from '../../apis/setting'
import { checkResponseDataStatus } from '../api'
import { enqueueErrorSnackbar } from '../snackbars'

/**
 * 従業員員職位一覧の取得
 *
 * 従業員職位覧を取得して store に格納する。<br />
 * ただし、すでに一覧が存在する場合には取得しない。
 */
export const fetchEmployeeTitles =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    const employeeTitles = store.getState().setting.employeeTitles
    if (employeeTitles?.length) {
      return Promise.resolve()
    }

    dispatch(actions.setEmployeeTitlesWaiting())
    return getEmployeeTitles()
      .then(checkResponseDataStatus)
      .then((data) => {
        dispatch(actions.setEmployeeTitles(data))
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const resetEmployeeTitles =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetEmployeeTitles())
  }
