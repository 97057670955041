import { fetcher, UseFetchArguments, UseFetchOption } from './useFetch'
import useSWRImmutable from 'swr/immutable'
import { ApiResponse } from '../../interfaces/api'

export type UseFetchImmutableArguments = UseFetchArguments

export type UseFetchImmutableKeys = UseFetchImmutableArguments | null | false

export type UseFetchImmutableOption<D, E> = UseFetchOption<D, E>

/**
 * 普遍データの取得
 *
 * 一度取得して以降、再取得を行わないキャッシュとして保管する。
 *
 * @param keys 取得キー
 * @param options オプション
 */
export const useFetchImmutable = <D extends ApiResponse, E = ApiResponse>(
  keys: UseFetchImmutableKeys,
  options?: UseFetchImmutableOption<D, E>,
) => useSWRImmutable<D, E>(keys, fetcher, options)
