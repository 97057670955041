import { ReactNode, useRef } from 'react'
import {
  MaterialDesignContent,
  SnackbarOrigin,
  SnackbarProvider,
} from 'notistack'
import { styled, Fade } from '@mui/material'
import {
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined,
  ReportProblemOutlined,
} from '@mui/icons-material'
import { colors } from '@/styles/colors'

type NotifyProviderProps = {
  children: ReactNode
}
const MAX_SNACK = 3

const anchorOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

export const NotifyProvider = (props: NotifyProviderProps) => {
  const snackbar = useRef<SnackbarProvider>(null)
  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={MAX_SNACK}
      anchorOrigin={anchorOrigin}
      iconVariant={{
        success: <CheckCircleOutline />,
        error: <ErrorOutline />,
        info: <InfoOutlined />,
        warning: <ReportProblemOutlined />,
      }}
      TransitionComponent={Fade}
      Components={{
        default: CustomComponent,
        success: CustomComponent,
        error: CustomComponent,
        warning: CustomComponent,
        info: CustomComponent,
      }}
      ref={snackbar}
      action={(key) => (
        <CloseLabel
          onClick={() =>
            snackbar.current && snackbar.current.closeSnackbar(key)
          }
        >
          閉じる
        </CloseLabel>
      )}
    >
      {props.children}
    </SnackbarProvider>
  )
}

const CustomComponent = styled(MaterialDesignContent)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,

  '&.notistack-MuiContent-success': {
    fontSize: theme.typography.fontSize,
    color: theme.palette.success.main,
    backgroundColor: colors.success['50'],
    borderColor: colors.success['100'],
  },
  '&.notistack-MuiContent-error': {
    fontSize: theme.typography.fontSize,
    color: theme.palette.error.main,
    backgroundColor: colors.error['50'],
    borderColor: colors.error['100'],
  },
  '&.notistack-MuiContent-warning': {
    fontSize: theme.typography.fontSize,
    color: theme.palette.warning.main,
    backgroundColor: colors.warning['50'],
    borderColor: colors.warning['100'],
  },
  '&.notistack-MuiContent-info': {
    fontSize: theme.typography.fontSize,
    color: theme.palette.info.main,
    backgroundColor: colors.info['50'],
    borderColor: colors.info['100'],
  },
  '& svg': {
    marginRight: theme.spacing(1),
  },
}))

const CloseLabel = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.fontSize,
  cursor: 'pointer',
  padding: theme.spacing(1),
  borderRadius: 4,
  transitionDuration: '200ms',

  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    transitionDuration: '200ms',
  },
}))
