import { ValidateError } from '../interfaces/validation'
import { setStringParams } from '../utils/strings'

type ValidateErrorControlType<T> = {
  add: (validateError: ValidateError<T>) => void
  remove: (item: string) => void
  removes: (...items: (keyof T)[]) => void
  values: () => ValidateError<T>[]
  clear: () => void
}

export const validateErrorControl = <T>(
  validateErrors: ValidateError<T>[],
): ValidateErrorControlType<T> => {
  let errors = [...validateErrors]
  const find = (item: keyof T): ValidateError<T> | undefined => {
    return errors.find((error) => error.item === item)
  }

  return {
    add: (validateError: ValidateError<T>): void => {
      if (!find(validateError.item)) {
        errors.push(validateError)
      }
    },
    remove: (item: string): void => {
      errors = errors.filter((error) => error.item !== item)
    },
    removes: (...items: (keyof T)[]): void => {
      errors = errors.filter((error) => !items.includes(error.item))
    },
    clear: (): void => {
      errors = []
    },
    values: (): ValidateError<T>[] => errors,
  }
}

type ErrorHelper<T> = {
  has: (item: keyof T) => boolean
  message: (item: keyof T) => string | null
  exist: () => boolean
}

export const useErrorMessage = <T>(
  validateErrors: ValidateError<T>[],
  paramsMap?: Record<string, string>,
): ErrorHelper<T> => {
  return {
    has: (item: keyof T): boolean => {
      return validateErrors.some((error) => error.item === item)
    },
    message: (item: keyof T): string | null => {
      const error = validateErrors.find((error) => error.item === item)
      if (!error) {
        return null
      }
      return paramsMap
        ? setStringParams(error.message, paramsMap)
        : error.message
    },
    exist: () => validateErrors.length > 0,
  }
}
