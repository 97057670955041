import { AppDispatch } from '../../../store'
import {
  deleteEventEmployees,
  EventEmployeesParams,
  exportResults,
  getEventCandidates,
  getEventEmployees,
  importResults,
  InviteEventEmployeeConditions,
  InviteEventEmployeeParams,
  postEventEmployees,
} from '../../../apis/healthCare/checkup'
import { actions } from '../../../modules/healthCare/checkup'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../../snackbars'
import {
  CheckupResult,
  CheckupResultForm,
  CheckupResultEmployee,
} from '../../../interfaces/healthCare/checkup'
import { CSVValidateRecord } from '../../csvUpload'
import { checkupEventStatus } from '../../../constants/healthCare/checkup'
import {
  checkResponseDataStatus,
  checkResponseStatus,
  downloadFile,
} from '../../api'

export const fetchEventEmployees =
  (eventId: string | undefined, query?: EventEmployeesParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingEventEmployees())
    return getEventEmployees(eventId, query)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(
          actions.setEventEmployees({
            employees: response.data.checkupSurveys,
          }),
        )
        dispatch(
          actions.setPaginationEventEmployees({
            pagination: response.meta ?? null,
          }),
        )
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchEventAddEmployees =
  (eventId: string | undefined, query?: EventEmployeesParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingEventCandidates())
    return getEventCandidates(eventId, query)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(
          actions.setEventCandidates({ employees: response.data.employees }),
        )
        dispatch(
          actions.setPaginationEventCandidates({
            pagination: response.meta ?? null,
          }),
        )
      })
      .catch((reason) => {
        dispatch(actions.resetWaitingEventCandidates())
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchEventRemoveEmployees =
  (eventId: string | undefined, query?: EventEmployeesParams) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId) {
      return Promise.resolve()
    }
    query = {
      ...(query || {}),
      eventStatusIds: [checkupEventStatus.NOT_CHECKED],
    }
    dispatch(actions.setWaitingEventTargetEmployees())
    return getEventEmployees(eventId, query)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(
          actions.setEventTargetEmployees({
            employees: response.data.checkupSurveys,
          }),
        )
        dispatch(
          actions.setPaginationEventTargetEmployees({
            pagination: response.meta ?? null,
          }),
        )
      })
      .catch((reason) => {
        dispatch(actions.resetWaitingEventTargetEmployees())
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const addEmployeeByIds =
  (eventId: string | undefined, ids: number[], callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    const params: InviteEventEmployeeParams = {
      targetType: 'ids',
      ids,
    }
    return dispatch(addEventEmployees(eventId, params, callback))
  }

export const addEmployeeByConditions =
  (
    eventId: string | undefined,
    conditions: InviteEventEmployeeConditions,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    const params: InviteEventEmployeeParams = {
      targetType: 'conditions',
      conditions,
    }
    return dispatch(addEventEmployees(eventId, params, callback))
  }

const addEventEmployees =
  (
    eventId: string | undefined,
    params: InviteEventEmployeeParams,
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId) {
      return Promise.resolve()
    }

    return postEventEmployees(eventId, params)
      .then(checkResponseDataStatus)
      .then(() => dispatch(enqueueSnackbar('対象者の追加が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const removeEventEmployees =
  (eventId: string | undefined, ids: number[], callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (!eventId) {
      return Promise.resolve()
    }

    return deleteEventEmployees(eventId, { ids })
      .then(checkResponseStatus)
      .then(() => dispatch(enqueueSnackbar('対象者の削除が完了しました')))
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const uploadResults =
  (
    eventId: string | undefined,
    params: { file: File },
    callback?: () => void,
  ) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (eventId === undefined) {
      return Promise.resolve()
    }
    dispatch(actions.setWaitingUploadCsv())
    return importResults(eventId, params)
      .then((response) => {
        if (response.status === 'error') {
          response.errors &&
            dispatch(
              actions.setErrorResponseResults({ errors: response.errors }),
            )
          throw new Error(response.message)
        } else {
          dispatch(enqueueSnackbar(`${response.message}`))
          return response.message
        }
      })
      .then(() => callback && callback())
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingUploadCsv())
      })
  }

export const downloadResults =
  (eventId: string | undefined, fileName?: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    if (eventId === undefined) {
      dispatch(enqueueErrorSnackbar('内部エラーが発生しました(eventId)'))
      return Promise.resolve()
    }
    dispatch(actions.setWaitingDownloadCsv())
    return exportResults(eventId)
      .then((response) => downloadFile(response, fileName))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingDownloadCsv())
      })
  }

export const resetEventTargetEmployees =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetEventTargetEmployees())
    dispatch(actions.resetEventCandidates())
  }

export const resetErrorResponseResult =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetErrorResponseResults())
  }

/** 従業員関連カラム */
type EmployeeRecordPick = Pick<
  CheckupResultEmployee,
  | 'code'
  | 'name'
  | 'gender'
  | 'birthday'
  | 'branchName'
  | 'departmentName'
  | 'checkupType'
>

/** 健診結果関連カラム */
type ResultRecordPick = Pick<
  CheckupResult,
  | 'id'
  | 'consultedAt'
  | 'clinicCode'
  | 'clinic'
  | 'decideTotal'
  | 'doctor'
  | 'opinion'
  | 'height'
  | 'weight'
  | 'bmi'
  | 'abdominalCircumference'
  | 'medicalHistory'
  | 'smokingHistory'
  | 'subjectiveSymptoms'
  | 'objectiveSymptoms'
  | 'visionNakedRight'
  | 'visionNakedLeft'
  | 'visionCorrectedRight'
  | 'visionCorrectedLeft'
  | 'ocularPressureRight'
  | 'ocularPressureLeft'
  | 'ocularFundusRight'
  | 'ocularFundusLeft'
  | 'audiometry1000Right'
  | 'audiometry1000Left'
  | 'audiometry4000Right'
  | 'audiometry4000Left'
  | 'simpleHearingRight'
  | 'simpleHearingLeft'
  | 'systolicBloodPressure'
  | 'diastolicBloodPressure'
  | 'cholesterol'
  | 'triglyceride'
  | 'ldl'
  | 'hdl'
  | 'nonHdl'
  | 'ast'
  | 'alt'
  | 'gammaGtp'
  | 'alp'
  | 'protein'
  | 'albumin'
  | 'bilirubin'
  | 'ldh'
  | 'amylase'
  | 'hbs'
  | 'hbv'
  | 'hcv'
  | 'uricAcid'
  | 'fastingBloodSugar'
  | 'hba1c'
  | 'proteinInUrine'
  | 'sugarInUrine'
  | 'occultBloodInUrine'
  | 'creatinine'
  | 'eGfr'
  | 'urinaryRedBloodCell'
  | 'urinaryWhiteBloodCell'
  | 'epithelialCell'
  | 'urinarySediment'
  | 'urinaryOthers'
  | 'redBloodCell'
  | 'whiteBloodCell'
  | 'hemoglobin'
  | 'hematocrit'
  | 'plateletCount'
  | 'bloodTyping'
  | 'crp'
  | 'electrocardiogramOpinion'
  | 'electrocardiogramJudgment'
  | 'chestXrayOpinion'
  | 'chestXrayJudgment'
  | 'sputumOpinion'
  | 'sputumJudgment'
  | 'respiratoryPercentVc'
  | 'respiratoryEfv1'
  | 'respiratoryPercentEfv1'
  | 'upperGastrointestinalOpinion'
  | 'upperGastrointestinalJudgment'
  | 'abdominalUltrasoundOpinion'
  | 'abdominalUltrasoundJudgment'
  | 'stoolOccultBlood1'
  | 'stoolOccultBlood2'
  | 'medicineOfHyperTension'
  | 'medicineOfBloodSugar'
  | 'medicineOfCholesterol'
  | 'historyOfStroke'
  | 'historyOfHeartDisease'
  | 'historyOfKidneyFailure'
  | 'historyOfAnemia'
  | 'habitOfSmoking'
  | 'habitOfGainedWeight'
  | 'habitOfMovingExercise'
  | 'habitOfLightExercise'
  | 'habitOfQuicklyWalking'
  | 'habitOfChewing'
  | 'habitOfEatInHurry'
  | 'habitOfEatInMidnight'
  | 'habitOfSnack'
  | 'habitOfWithoutBreakfast'
  | 'habitOfDrinking'
  | 'habitOfDrinkingAmount'
  | 'habitOfSleeping'
  | 'habitOfImprovement'
  | 'habitOfGuidance'
  | 'medicalDecide'
  | 'workingDecide'
  | 'interview'
  | 'afterMeasureStatus'
  | 'memo'
  | 'decidedAt'
  | 'decidedUserName'
  | 'afterMeasureSendAt'
  | 'afterMeasureDecideReport'
  | 'afterMeasureConsultedAt'
  | 'afterMeasureClinic'
  | 'afterMeasureOpinion'
  | 'afterMeasureRestrict'
  | 'afterMeasureCheckStatus'
  | 'afterMeasureSystolicBloodPressure'
  | 'afterMeasureDiastolicBloodPressure'
  | 'afterMeasureRepliedAt'
  | 'finalDecide'
  | 'finalDecideMemo'
  | 'finalDecidedAt'
  | 'finalDecidedUserName'
>

/** 健診結果取込レコード */
type ResultRecord = { [P in keyof EmployeeRecordPick]: string } & {
  [P in keyof ResultRecordPick]: string
}

type ValidationRules = {
  maxLength?: number
  number?: boolean
  numeric?: boolean
  string?: boolean
  enum?: string[]
}

// prettier-ignore
export const resultsCsvHeaders: CSVValidateRecord<ResultRecord>[] = [
  { index: 0, name: 'id', label: '受診者ID' },
  { index: 1, name: 'code', label: '従業員ID' },
  { index: 2, name: 'name', label: '従業員名', nullable: true },
  { index: 3, name: 'gender', label: '性別', nullable: true },
  { index: 4, name: 'birthday', label: '生年月日', nullable: true },
  { index: 5, name: 'branchName', label: '事業所', nullable: true },
  { index: 6, name: 'departmentName', label: '部署', nullable: true },
  { index: 7, name: 'checkupType', label: '種別', nullable: true },
  { index: 8, name: 'consultedAt', label: '受診日', date: true },
  { index: 9, name: 'clinicCode', label: '医療機関ID', nullable: true },
  { index: 10, name: 'clinic', label: '医療機関名', nullable: true },
  { index: 11, name: 'decideTotal', label: '総合判定', nullable: true },
  { index: 12, name: 'doctor', label: '医師名', nullable: true },
  { index: 13, name: 'opinion', label: '医師の意見', nullable: true },
  { index: 14, name: 'height', label: '身長(cm)', nullable: true, numeric: true },
  { index: 15, name: 'weight', label: '体重(kg)', nullable: true, numeric: true },
  { index: 16, name: 'bmi', label: 'BMI(kg/m2)', nullable: true, numeric: true },
  { index: 17, name: 'abdominalCircumference', label: '腹囲(cm)', nullable: true, numeric: true },
  { index: 18, name: 'medicalHistory', label: '既往歴', nullable: true },
  { index: 19, name: 'smokingHistory', label: '喫煙歴', nullable: true },
  { index: 20, name: 'subjectiveSymptoms', label: '自覚症状', nullable: true },
  { index: 21, name: 'objectiveSymptoms', label: '診察(他覚症状)所見', nullable: true },
  { index: 22, name: 'visionNakedRight', label: '視力ー裸眼右', nullable: true, numeric: true },
  { index: 23, name: 'visionNakedLeft', label: '視力ー裸眼左', nullable: true, numeric: true },
  { index: 24, name: 'visionCorrectedRight', label: '視力―矯正右', nullable: true, numeric: true },
  { index: 25, name: 'visionCorrectedLeft', label: '視力ー矯正左', nullable: true, numeric: true },
  { index: 26, name: 'ocularPressureRight', label: '眼圧ー右(mmHg)', nullable: true, numeric: true },
  { index: 27, name: 'ocularPressureLeft', label: '眼圧ー左(mmHg)', nullable: true, numeric: true },
  { index: 28, name: 'ocularFundusRight', label: '眼底ー右', nullable: true },
  { index: 29, name: 'ocularFundusLeft', label: '眼底ー左', nullable: true },
  { index: 30, name: 'audiometry1000Right', label: '聴力ー1000Hz右', nullable: true },
  { index: 31, name: 'audiometry1000Left', label: '聴力ー1000Hz左', nullable: true },
  { index: 32, name: 'audiometry4000Right', label: '聴力ー4000Hz右', nullable: true },
  { index: 33, name: 'audiometry4000Left', label: '聴力ー4000Hz左', nullable: true },
  { index: 34, name: 'simpleHearingRight', label: '聴力ーその他(簡易聴力)右', nullable: true },
  { index: 35, name: 'simpleHearingLeft', label: '聴力ーその他(簡易聴力)左', nullable: true },
  { index: 36, name: 'systolicBloodPressure', label: '収縮期血圧(mmHg)', nullable: true, numeric: true },
  { index: 37, name: 'diastolicBloodPressure', label: '拡張期血圧(mmHg)', nullable: true, numeric: true },
  { index: 38, name: 'cholesterol', label: '総コレステロール(mg/dL)', nullable: true, numeric: true },
  { index: 39, name: 'triglyceride', label: '中性脂肪(mg/dL)', nullable: true, numeric: true },
  { index: 40, name: 'ldl', label: 'LDL(mg/dL)', nullable: true, numeric: true },
  { index: 41, name: 'hdl', label: 'HDL(mg/dL)', nullable: true, numeric: true },
  { index: 42, name: 'nonHdl', label: 'Non-HDL(mg/dL)', nullable: true, numeric: true },
  { index: 43, name: 'ast', label: 'AST(GOT)(U/L)', nullable: true, numeric: true },
  { index: 44, name: 'alt', label: 'ALT(GPT)(U/L)', nullable: true, numeric: true },
  { index: 45, name: 'gammaGtp', label: 'γGTP(U/L)', nullable: true, numeric: true },
  { index: 46, name: 'alp', label: 'ALP(U/L)', nullable: true, numeric: true },
  { index: 47, name: 'protein', label: '総蛋白(g/dL)', nullable: true, numeric: true },
  { index: 48, name: 'albumin', label: 'アルブミン(g/dL)', nullable: true, numeric: true },
  { index: 49, name: 'bilirubin', label: '総ビリルビン(mg/dL)', nullable: true, numeric: true },
  { index: 50, name: 'ldh', label: 'LDH(U/L)', nullable: true, numeric: true },
  { index: 51, name: 'amylase', label: 'アミラーゼ(U/L)', nullable: true, numeric: true },
  { index: 52, name: 'hbs', label: 'HBS抗原', nullable: true, enum: ['0', '1'] },
  { index: 53, name: 'hbv', label: 'HBV抗体', nullable: true, enum: ['0', '1'] },
  { index: 54, name: 'hcv', label: 'HCV抗体', nullable: true, enum: ['0', '1'] },
  { index: 55, name: 'uricAcid', label: '尿酸(mg/dL)', nullable: true, numeric: true },
  { index: 56, name: 'fastingBloodSugar', label: '空腹時血糖(mg/dL)', nullable: true, numeric: true },
  { index: 57, name: 'hba1c', label: 'HbA1c(%)', nullable: true, numeric: true },
  { index: 58, name: 'proteinInUrine', label: '尿蛋白', nullable: true, enum: ['0', '1', '2', '3', '4'] },
  { index: 59, name: 'sugarInUrine', label: '尿糖', nullable: true, enum: ['0', '1', '2', '3', '4'] },
  { index: 60, name: 'occultBloodInUrine', label: '尿潜血', nullable: true, enum: ['0', '1', '2', '3', '4'] },
  { index: 61, name: 'creatinine', label: 'クレアチニン(mg/dL)', nullable: true, numeric: true },
  { index: 62, name: 'eGfr', label: 'eGFR', nullable: true, numeric: true },
  { index: 63, name: 'urinaryRedBloodCell', label: '赤血球(/HPF)', nullable: true, enum: ['0', '1', '2'] },
  { index: 64, name: 'urinaryWhiteBloodCell', label: '白血球(/HPF)', nullable: true, enum: ['0', '1', '2'] },
  { index: 65, name: 'epithelialCell', label: '上皮細胞(/HPF)', nullable: true, enum: ['0', '1'] },
  { index: 66, name: 'urinarySediment', label: '円柱(/WF)', nullable: true, numeric: true },
  { index: 67, name: 'urinaryOthers', label: '尿沈渣ーその他', nullable: true },
  { index: 68, name: 'redBloodCell', label: '赤血球(104/μＬ)', nullable: true, numeric: true },
  { index: 69, name: 'whiteBloodCell', label: '白血球(103/μＬ)', nullable: true, numeric: true },
  { index: 70, name: 'hemoglobin', label: '血色素(g/dL)', nullable: true, numeric: true },
  { index: 71, name: 'hematocrit', label: 'ヘマトクリット(%)', nullable: true, numeric: true },
  { index: 72, name: 'plateletCount', label: '血小板数(104/μＬ)', nullable: true, numeric: true },
  { index: 73, name: 'bloodTyping', label: '血液型', nullable: true, enum: ['A', 'B', 'AB', 'O'] },
  { index: 74, name: 'crp', label: 'CRP(mg/dL)', nullable: true, numeric: true },
  { index: 75, name: 'electrocardiogramOpinion', label: '心電図ー所見', nullable: true },
  { index: 76, name: 'electrocardiogramJudgment', label: '心電図－判定', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 77, name: 'chestXrayOpinion', label: '胸部X線ー所見', nullable: true },
  { index: 78, name: 'chestXrayJudgment', label: '胸部X線ー判定', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 79, name: 'sputumOpinion', label: '喀痰ー所見', nullable: true },
  { index: 80, name: 'sputumJudgment', label: '喀痰ー判定', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 81, name: 'respiratoryPercentVc', label: '％肺活量(%)', nullable: true, numeric: true },
  { index: 82, name: 'respiratoryEfv1', label: '1秒率(%)', nullable: true, numeric: true },
  { index: 83, name: 'respiratoryPercentEfv1', label: '％1秒量(%)', nullable: true, numeric: true },
  { index: 84, name: 'upperGastrointestinalOpinion', label: '上部消化管X線ー所見', nullable: true },
  { index: 85, name: 'upperGastrointestinalJudgment', label: '上部消化管X線ー判定', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 86, name: 'abdominalUltrasoundOpinion', label: '腹部超音波ー所見', nullable: true },
  { index: 87, name: 'abdominalUltrasoundJudgment', label: '腹部超音波ー判定', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 88, name: 'stoolOccultBlood1', label: '便潜血ー1回目', nullable: true, enum: ['0', '1'] },
  { index: 89, name: 'stoolOccultBlood2', label: '便潜血ー2回目', nullable: true, enum: ['0', '1'] },
  { index: 90, name: 'medicineOfHyperTension', label: '(1)服薬1(血圧)', nullable: true, enum: ['1', '2'] },
  { index: 91, name: 'medicineOfBloodSugar', label: '(2)服薬2(血糖)', nullable: true, enum: ['1', '2'] },
  { index: 92, name: 'medicineOfCholesterol', label: '(3)服薬3(脂質)', nullable: true, enum: ['1', '2'] },
  { index: 93, name: 'historyOfStroke', label: '(4)既往歴1(脳血管)', nullable: true, enum: ['1', '2'] },
  { index: 94, name: 'historyOfHeartDisease', label: '(5)既往歴2(心血管)', nullable: true, enum: ['1', '2'] },
  { index: 95, name: 'historyOfKidneyFailure', label: '(6)既往歴3(腎不全・人工透析)', nullable: true, enum: ['1', '2'] },
  { index: 96, name: 'historyOfAnemia', label: '(7)貧血', nullable: true, enum: ['1', '2'] },
  { index: 97, name: 'habitOfSmoking', label: '(8)喫煙', nullable: true, enum: ['1', '2'] },
  { index: 98, name: 'habitOfGainedWeight', label: '(9)20歳からの体重変化', nullable: true, enum: ['1', '2'] },
  { index: 99, name: 'habitOfMovingExercise', label: '(10)30分以上の運動習慣', nullable: true, enum: ['1', '2'] },
  { index: 100, name: 'habitOfLightExercise', label: '(11)歩行または身体活動', nullable: true, enum: ['1', '2'] },
  { index: 101, name: 'habitOfQuicklyWalking', label: '(12)歩行速度', nullable: true, enum: ['1', '2'] },
  { index: 102, name: 'habitOfChewing', label: '(13)咀嚼', nullable: true, enum: ['1', '2', '3'] },
  { index: 103, name: 'habitOfEatInHurry', label: '(14)食べ方1(早食い等)', nullable: true, enum: ['1', '2', '3'] },
  { index: 104, name: 'habitOfEatInMidnight', label: '(15)食べ方2(就寝前)', nullable: true, enum: ['1', '2'] },
  { index: 105, name: 'habitOfSnack', label: '(16)食べ方3(間食)', nullable: true, enum: ['1', '2', '3'] },
  { index: 106, name: 'habitOfWithoutBreakfast', label: '(17)食習慣(朝食)', nullable: true, enum: ['1', '2'] },
  { index: 107, name: 'habitOfDrinking', label: '(18)飲酒頻度', nullable: true, enum: ['1', '2', '3'] },
  { index: 108, name: 'habitOfDrinkingAmount', label: '(19)飲酒量', nullable: true, enum: ['1', '2', '3', '4'] },
  { index: 109, name: 'habitOfSleeping', label: '(20)睡眠', nullable: true, enum: ['1', '2'] },
  { index: 110, name: 'habitOfImprovement', label: '(21)生活習慣の改善', nullable: true, enum: ['1', '2', '3', '4', '5'] },
  { index: 111, name: 'habitOfGuidance', label: '(22)保健指導の希望', nullable: true, enum: ['1', '2'] },
  { index: 112, name: 'medicalDecide', label: '医療判定', nullable: true },
  { index: 113, name: 'workingDecide', label: '就業判定', nullable: true },
  { index: 114, name: 'interview', label: '面談', nullable: true },
  { index: 115, name: 'afterMeasureStatus', label: '事後措置', nullable: true },
  { index: 116, name: 'memo', label: '事後措置対象項目', nullable: true },
  { index: 117, name: 'decidedAt', label: '一次判定日時', nullable: true },
  { index: 118, name: 'decidedUserName', label: '一次判定者', nullable: true },
  { index: 119, name: 'afterMeasureSendAt', label: '事後措置票配信', nullable: true },
  { index: 120, name: 'afterMeasureDecideReport', label: '受診報告', nullable: true },
  { index: 121, name: 'afterMeasureConsultedAt', label: '事後措置ー受診日', nullable: true, date: true },
  { index: 122, name: 'afterMeasureClinic', label: '事後措置ー医療機関名', nullable: true },
  { index: 123, name: 'afterMeasureOpinion', label: '就業に関する診察医の意見', nullable: true },
  { index: 124, name: 'afterMeasureRestrict', label: '必要な制限の内容', nullable: true },
  { index: 125, name: 'afterMeasureCheckStatus', label: '検査・治療状況', nullable: true },
  { index: 126, name: 'afterMeasureSystolicBloodPressure', label: '改善後の収縮期血圧', nullable: true },
  { index: 127, name: 'afterMeasureSystolicBloodPressure', label: '改善後の拡張期血圧', nullable: true },
  { index: 128, name: 'afterMeasureRepliedAt', label: '受診報告日時', nullable: true },
  { index: 129, name: 'finalDecide', label: '最終判定', nullable: true },
  { index: 130, name: 'finalDecideMemo', label: '最終判定コメント', nullable: true },
  { index: 131, name: 'finalDecidedAt', label: '最終判定日時', nullable: true },
  { index: 132, name: 'finalDecidedUserName', label: '最終判定者', nullable: true }
]

export const validateMaxLength = (
  value: string,
  maxLength: number | undefined,
): string[] => {
  if (value && maxLength && value.length > maxLength) {
    return [`${maxLength}文字以内で入力してください`]
  }
  return []
}

export const validateNumeric = (value: string | number): string[] => {
  const errors = []
  const numberValue = Number(value)

  if (isNaN(numberValue)) {
    errors.push('数値を入力してください')
  }
  return errors
}

export const validateInteger = (value: string): string[] => {
  if (value && !Number.isInteger(Number(value))) {
    return ['整数を入力してください']
  }
  return []
}

export const validateField = (
  value: string,
  rules: ValidationRules,
): string[] => {
  const errors = []

  if (rules.maxLength) {
    errors.push(...validateMaxLength(value, rules.maxLength))
  }
  if (rules.numeric) {
    errors.push(...validateNumeric(value))
  }
  if (rules.number) {
    errors.push(...validateInteger(value))
  }

  return errors
}

export const resultFormRules: Partial<{
  [key in keyof CheckupResultForm]: ValidationRules
}> = {
  decideTotal: { maxLength: 1000 },
  doctor: { maxLength: 80 },
  opinion: { maxLength: 1000 },
  height: { numeric: true },
  weight: { numeric: true },
  bmi: { numeric: true },
  abdominalCircumference: { numeric: true },
  medicalHistory: { maxLength: 200 },
  smokingHistory: { maxLength: 200 },
  subjectiveSymptoms: { maxLength: 200 },
  objectiveSymptoms: { maxLength: 200 },
  visionNakedRight: { numeric: true },
  visionNakedLeft: { numeric: true },
  visionCorrectedRight: { numeric: true },
  visionCorrectedLeft: { numeric: true },
  ocularPressureRight: { numeric: true },
  ocularPressureLeft: { numeric: true },
  ocularFundusRight: { maxLength: 200 },
  ocularFundusLeft: { maxLength: 200 },
  audiometry1000Right: { maxLength: 200 },
  audiometry1000Left: { maxLength: 200 },
  audiometry4000Right: { maxLength: 200 },
  audiometry4000Left: { maxLength: 200 },
  simpleHearingRight: { maxLength: 200 },
  simpleHearingLeft: { maxLength: 200 },
  systolicBloodPressure: { numeric: true },
  diastolicBloodPressure: { numeric: true },
  cholesterol: { numeric: true },
  triglyceride: { numeric: true },
  ldl: { numeric: true },
  hdl: { numeric: true },
  nonHdl: { numeric: true },
  ast: { numeric: true },
  alt: { numeric: true },
  gammaGtp: { numeric: true },
  alp: { numeric: true },
  protein: { numeric: true },
  albumin: { numeric: true },
  bilirubin: { numeric: true },
  ldh: { numeric: true },
  amylase: { numeric: true },
  hbs: { number: true },
  hbv: { number: true },
  hcv: { number: true },
  uricAcid: { numeric: true },
  fastingBloodSugar: { numeric: true },
  hba1c: { numeric: true },
  proteinInUrine: { number: true },
  sugarInUrine: { number: true },
  occultBloodInUrine: { number: true },
  creatinine: { numeric: true },
  eGfr: { numeric: true },
  urinaryRedBloodCell: { number: true },
  urinaryWhiteBloodCell: { number: true },
  epithelialCell: { number: true },
  urinarySediment: { numeric: true },
  urinaryOthers: { maxLength: 200 },
  redBloodCell: { numeric: true },
  whiteBloodCell: { numeric: true },
  hemoglobin: { numeric: true },
  hematocrit: { numeric: true },
  plateletCount: { numeric: true },
  crp: { numeric: true },
  electrocardiogramOpinion: { maxLength: 200 },
  electrocardiogramJudgment: { number: true },
  chestXrayOpinion: { maxLength: 200 },
  chestXrayJudgment: { number: true },
  sputumOpinion: { maxLength: 200 },
  sputumJudgment: { number: true },
  respiratoryPercentVc: { numeric: true },
  respiratoryEfv1: { numeric: true },
  respiratoryPercentEfv1: { numeric: true },
  upperGastrointestinalOpinion: { maxLength: 200 },
  upperGastrointestinalJudgment: { number: true },
  abdominalUltrasoundOpinion: { maxLength: 200 },
  abdominalUltrasoundJudgment: { number: true },
  stoolOccultBlood1: { number: true },
  stoolOccultBlood2: { number: true },
  medicineOfHyperTension: { number: true },
  medicineOfBloodSugar: { number: true },
  medicineOfCholesterol: { number: true },
  historyOfStroke: { number: true },
  historyOfHeartDisease: { number: true },
  historyOfKidneyFailure: { number: true },
  historyOfAnemia: { number: true },
  habitOfSmoking: { number: true },
  habitOfGainedWeight: { number: true },
  habitOfMovingExercise: { number: true },
  habitOfLightExercise: { number: true },
  habitOfQuicklyWalking: { number: true },
  habitOfChewing: { number: true },
  habitOfEatInHurry: { number: true },
  habitOfEatInMidnight: { number: true },
  habitOfSnack: { number: true },
  habitOfWithoutBreakfast: { number: true },
  habitOfDrinking: { number: true },
  habitOfDrinkingAmount: { number: true },
  habitOfSleeping: { number: true },
  habitOfImprovement: { number: true },
  habitOfGuidance: { number: true },
}

export const getValidationRules = (field: keyof CheckupResultForm) => {
  const rule = resultFormRules[field]

  return {
    validate: (value: string | number | null) => {
      if (rule) {
        const errors = validateField(String(value), rule)
        return errors.length > 0 ? errors.join('\n') : true
      }
      return true
    },
  }
}
