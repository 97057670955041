import { VariantType } from 'notistack'
import { AppDispatch } from '../store'
import { actions } from '../modules/snackbar'
import { SnackbarOptions } from '../interfaces/snackbar'

export const enqueueSnackbar =
  (message: string, variant: VariantType = 'success') =>
  (
    dispatch: AppDispatch,
  ): {
    payload: { message: string; options: SnackbarOptions }
    type: string
  } => {
    return dispatch(actions.enqueue({ message, options: { variant } }))
  }

export const enqueueErrorSnackbar =
  (reason: string | Error) =>
  (
    dispatch: AppDispatch,
  ): {
    payload: { message: string; options: SnackbarOptions }
    type: string
  } => {
    let message = reason instanceof Error ? reason.message : reason
    if (!message) {
      message = 'システムエラーが発生しました'
    }
    return dispatch(enqueueSnackbar(message, 'error'))
  }
