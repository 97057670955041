import { ComponentPropsWithoutRef } from 'react'

export type TopTitleLogoProps = ComponentPropsWithoutRef<'svg'>

/**
 * タイトルロゴ画像
 *
 * elnavi_logo_main.svg を移植したもの。
 * 本来は MainTitleLogo と共通化したいが、アイコンの形が微妙に異なっている。
 * そのため、別コンポーネントとして定義。
 *
 * @see MainTitleLogo
 * @see elnavi_logo_main.svg
 */
export const TopTitleLogo = (props: TopTitleLogoProps) => (
  <svg
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 680 200"
    overflow="visible"
    {...props}
  >
    <g>
      <path
        fill="#083782"
        d="M253.6,83.8h-37.9v20.4h34.9c2.6,0,4.5,0.6,5.8,1.7c1.3,1.1,1.9,2.7,1.9,4.6s-0.6,3.4-1.9,4.6
		s-3.2,1.8-5.8,1.8h-34.9v23.6h39.2c2.6,0,4.6,0.6,6,1.8c1.3,1.2,2,2.9,2,4.9c0,2-0.7,3.6-2,4.8s-3.3,1.8-6,1.8h-45.7
		c-3.7,0-6.3-0.8-7.9-2.4c-1.6-1.6-2.4-4.2-2.4-7.9V81.1c0-2.4,0.4-4.4,1.1-5.9s1.8-2.6,3.4-3.3s3.5-1.1,5.9-1.1h44.4
		c2.7,0,4.7,0.6,6,1.8s2,2.8,2,4.7c0,2-0.6,3.5-2,4.7S256.2,83.8,253.6,83.8z"
      />
      <path
        fill="#083782"
        d="M292.7,79.6V140h34.1c2.7,0,4.8,0.7,6.3,2c1.5,1.3,2.2,3,2.2,5c0,2-0.7,3.7-2.1,5c-1.4,1.3-3.5,1.9-6.3,1.9
		h-40.6c-3.7,0-6.3-0.8-7.9-2.4c-1.6-1.6-2.4-4.2-2.4-7.9V79.6c0-3.4,0.8-5.9,2.3-7.7s3.5-2.6,6-2.6c2.5,0,4.6,0.8,6.1,2.5
		C291.9,73.6,292.7,76.1,292.7,79.6z"
      />
      <path
        fill="#083782"
        d="M365.5,130.5h-17.9c-2.7,0-4.8-0.6-6.2-1.9c-1.4-1.3-2.1-2.9-2.1-4.9c0-2,0.7-3.7,2.1-5
		c1.4-1.3,3.5-1.9,6.3-1.9h17.9c2.8,0,4.9,0.6,6.3,1.9c1.4,1.3,2.1,2.9,2.1,5c0,2-0.7,3.6-2,4.9
		C370.5,129.9,368.4,130.5,365.5,130.5z"
      />
      <path
        fill="#083782"
        d="M406.8,79.5l32.3,48.9V79c0-3.2,0.7-5.6,2.1-7.2s3.2-2.4,5.6-2.4c2.4,0,4.3,0.8,5.7,2.4
		c1.4,1.6,2.1,4,2.1,7.2v65.3c0,7.3-3,10.9-9.1,10.9c-1.5,0-2.9-0.2-4.1-0.6s-2.3-1.1-3.4-2.1c-1.1-0.9-2-2.1-3-3.3
		c-0.9-1.3-1.8-2.6-2.7-3.9L400.9,97v48.6c0,3.2-0.7,5.6-2.2,7.2s-3.4,2.4-5.7,2.4c-2.4,0-4.3-0.8-5.7-2.5c-1.4-1.6-2.1-4-2.1-7.2
		V81.6c0-2.7,0.3-4.8,0.9-6.4c0.7-1.7,1.9-3.1,3.6-4.2c1.7-1.1,3.5-1.6,5.4-1.6c1.5,0,2.8,0.2,3.9,0.7c1.1,0.5,2,1.2,2.8,2
		s1.6,1.9,2.5,3.2C405.1,76.7,405.9,78,406.8,79.5z"
      />
      <path
        fill="#083782"
        d="M511.1,146.5c-3.7,2.9-7.3,5.1-10.8,6.5c-3.5,1.4-7.4,2.2-11.8,2.2c-4,0-7.5-0.8-10.5-2.4s-5.3-3.7-6.9-6.4
		s-2.4-5.6-2.4-8.7c0-4.2,1.3-7.8,4-10.8c2.7-3,6.4-5,11-6c1-0.2,3.4-0.7,7.3-1.5s7.2-1.5,10-2.2c2.8-0.7,5.8-1.5,9-2.4
		c-0.2-4.1-1-7.1-2.5-9c-1.5-1.9-4.5-2.9-9-2.9c-3.9,0-6.9,0.6-8.9,1.6c-2,1.1-3.7,2.7-5.1,4.9c-1.4,2.2-2.4,3.6-3,4.3
		c-0.6,0.7-1.8,1.1-3.8,1.1c-1.7,0-3.2-0.6-4.5-1.7c-1.3-1.1-1.9-2.5-1.9-4.3c0-2.7,1-5.4,2.9-7.9c1.9-2.6,4.9-4.7,9-6.3
		c4.1-1.7,9.2-2.5,15.2-2.5c6.8,0,12.1,0.8,16,2.4s6.6,4.1,8.2,7.6c1.6,3.5,2.4,8.1,2.4,13.8c0,3.6,0,6.7,0,9.2s0,5.3-0.1,8.4
		c0,2.9,0.5,5.9,1.4,9.1s1.4,5.2,1.4,6.1c0,1.6-0.8,3-2.2,4.3s-3.2,1.9-5.1,1.9c-1.6,0-3.2-0.8-4.7-2.2
		C514.4,151.5,512.8,149.4,511.1,146.5z M510,124.2c-2.3,0.8-5.6,1.7-9.9,2.6c-4.3,0.9-7.3,1.6-9,2s-3.2,1.3-4.8,2.6s-2.3,3-2.3,5.3
		c0,2.3,0.9,4.3,2.7,6c1.8,1.6,4.1,2.5,7,2.5c3.1,0,5.9-0.7,8.5-2c2.6-1.3,4.5-3.1,5.7-5.2c1.4-2.3,2.1-6.2,2.1-11.6L510,124.2
		L510,124.2z"
      />
      <path
        fill="#083782"
        d="M551.8,102l12.5,35.1l13.5-36.7c1.1-2.9,2.1-5,3.1-6.3s2.6-1.8,4.6-1.8c2,0,3.6,0.7,5,2s2.1,2.9,2.1,4.6
		c0,0.7-0.1,1.5-0.4,2.4s-0.5,1.8-0.8,2.6c-0.3,0.8-0.7,1.7-1.1,2.7l-14.8,36.8c-0.4,1.1-1,2.4-1.6,4c-0.7,1.6-1.4,3-2.2,4.2
		c-0.8,1.1-1.8,2.1-3,2.7s-2.6,1-4.3,1c-2.2,0-3.9-0.5-5.2-1.5s-2.3-2.1-2.9-3.3s-1.7-3.5-3.1-7.1L538.4,107c-0.3-0.9-0.7-1.8-1-2.7
		s-0.7-1.8-0.9-2.8c-0.2-0.9-0.4-1.8-0.4-2.4c0-1.1,0.3-2.1,1-3.1c0.6-1,1.5-1.9,2.7-2.6c1.1-0.7,2.4-1,3.7-1c2.6,0,4.3,0.7,5.3,2.2
		S550.7,98.5,551.8,102z"
      />
      <path
        fill="#083782"
        d="M611.6,85.2c-2.2,0-4-0.7-5.5-2s-2.3-3.2-2.3-5.6c0-2.2,0.8-4,2.3-5.4c1.6-1.4,3.4-2.1,5.5-2.1
		c2,0,3.8,0.6,5.3,1.9c1.5,1.3,2.3,3.2,2.3,5.6c0,2.4-0.8,4.2-2.3,5.6C615.5,84.5,613.7,85.2,611.6,85.2z M619.2,101.2v44.7
		c0,3.1-0.7,5.4-2.2,7s-3.3,2.4-5.6,2.4s-4.1-0.8-5.5-2.4s-2.1-3.9-2.1-7v-44.2c0-3.1,0.7-5.4,2.1-6.9c1.4-1.6,3.3-2.3,5.5-2.3
		s4.1,0.8,5.6,2.3C618.5,96.2,619.2,98.4,619.2,101.2z"
      />
    </g>
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#083782"
              d="M174,75.2c0-29.6-24-53.5-53.6-53.5s-53.6,24-53.6,53.5c0,12.8,5,24.1,11.9,33.7
					c11.3,15.6,41.6,64.8,41.6,64.8s31.7-47.9,42.1-63.4C169.2,100.5,174,87.9,174,75.2z"
            />
            <path
              fill="#083782"
              d="M168.8,75.2c0.2-5.3-0.5-10.7-1.9-15.8c-0.4-1.3-0.7-2.6-1.2-3.9l-1.5-3.8c-0.6-1.2-1.3-2.4-1.9-3.6
					c-0.2-0.3-0.3-0.6-0.5-0.9l-0.6-0.8l-1.1-1.7c-3.1-4.5-7-8.6-11.5-11.9c-4.5-3.3-9.7-5.7-15.1-7.1c-5.4-1.4-11.1-2-16.7-1.4
					c-5.6,0.5-11,1.9-16.1,4.2c-5.1,2.3-9.7,5.5-13.7,9.2c-4,3.8-7.5,8.2-9.9,13.1c-2.5,4.9-4.2,10.1-5.1,15.4l-0.5,4l-0.1,4.1
					c0,2.7,0.3,5.3,0.8,7.9c1,5.2,3,10.2,5.7,14.9c1.3,2.4,2.8,4.6,4.4,6.8l2.6,3.4c0.9,1.2,1.8,2.4,2.7,3.6
					c14.1,19.2,27.4,38.4,41.2,57.6l0,0l-16.7-0.3l16.8-23l16.7-23l8.3-11.5l4.1-5.6c1.2-1.8,2.4-3.6,3.4-5.5
					C165.7,92.1,168.4,83.7,168.8,75.2z M179.2,75.2c0.4,10.5-2.1,20.8-6.4,30.1c-1.1,2.3-2.3,4.6-3.6,6.9l-3.8,6.2l-7.4,12.1
					l-14.8,24.2l-14.7,24.3l-0.2,0.3c-2.7,4.5-8.5,5.9-13,3.2c-1.5-0.9-2.7-2.3-3.5-3.7v0c-5.4-10.4-11-20.7-16.8-30.9
					c-5.7-10.2-11.6-20.4-17.6-30.2c-0.8-1.2-1.5-2.5-2.3-3.6l-2.4-3.8c-1.7-2.7-3.2-5.4-4.6-8.3c-2.7-5.7-4.6-11.9-5.4-18.3
					c-0.4-3.2-0.5-6.4-0.3-9.6l0.4-4.7l0.8-4.7c1.4-6.2,3.7-12.1,6.9-17.5C77.1,36.4,87,28,98.4,23.3c11.5-4.5,24.3-5.3,36.1-2.1
					l2.2,0.6c0.7,0.2,1.4,0.5,2.2,0.7l2.2,0.8l1.1,0.4l1,0.5c1.4,0.6,2.8,1.2,4.2,1.9l4,2.3c5.2,3.2,10,7.3,14.1,12
					C173.8,49.8,178.6,62.3,179.2,75.2z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M124.1,116.9c-11.9,0-21.6-2.8-29.2-8.5c-8.4-6.3-12.6-15.1-12.6-26.5c0-12.6,3.3-23.3,9.9-32
			c7.4-9.7,17.3-14.5,29.8-14.5c8.7,0,15.9,1.4,21.6,4.2c7.5,3.7,11.3,9.4,11.3,17.2c0,5.5-3.1,10.3-9.2,14.6
			c-2.8,1.9-8.4,4.8-16.8,8.5L103.8,91c2.4,2.6,5.3,4.5,8.7,5.8c3.4,1.3,7.3,1.9,11.6,1.9c6.7,0,12.3-1.4,17-4.1
			c4.1-2.4,7.4-3.7,9.9-3.7c5.2,0,7.8,2.5,7.8,7.5c0,5.7-4.2,10.3-12.5,13.9C139.3,115.4,131.9,116.9,124.1,116.9z M122.1,53.5
			c-4.6,0-8.6,1.5-11.9,4.6c-3.3,3.1-5.9,7.7-7.9,13.9c5.6-2.4,11.2-4.9,16.8-7.2c6.7-3,12.1-5.8,16.1-8.4
			C131.9,54.4,127.5,53.5,122.1,53.5z"
        />
      </g>
    </g>
  </svg>
)
