import { MutatorOptions, useSWRConfig } from 'swr'
import { useCallback } from 'react'

export const useCacheConfig = () => {
  const { mutate } = useSWRConfig()

  /** すべてのキャッシュを削除 */
  const clearCacheAll = useCallback(
    (options?: MutatorOptions) => {
      return mutate(() => true, undefined, options)
    },
    [mutate],
  )

  return {
    mutate,
    clearCacheAll,
  }
}
