import {
  CheckupFinalResultEmployee,
  CheckupFinalResultEmployeeDetail,
  CheckupResult,
  CheckupResultDocument,
  CheckupResultEmployee,
  Clinic,
} from '@/interfaces/healthCare/checkup'
import { ApiDataResponse } from '@/interfaces/api'
import { api } from '../../base'

const base = `${api.V1}/checkup`
const results = `${base}/results`
const events = `${api.V1}/checkup/events`
const finalResults = `${base}/final_results`

export type CheckupResultEmployeesParams = {
  branchIds?: number[]
  departmentIds?: number[]
  employeeStatusIds?: number[]
  typeNameIds?: number[]
  medicalDecideIds?: number[]
  workingDecideIds?: number[]
  interviewIds?: number[]
  afterMeasureStatusIds?: number[]
  name?: string | null
  decidedUserName?: string | null
  sendAt?: number
  page?: number
}

export type CheckupResultEmployeeParams = Pick<
  CheckupResultEmployee,
  | 'medicalDecideId'
  | 'workingDecideId'
  | 'interviewId'
  | 'afterMeasureStatusId'
  | 'memo'
> & {
  /** 判定者(ユーザー)ID */
  userId: number
}

export type CheckupResultSendMailConditions = Omit<
  CheckupResultEmployeesParams,
  'afterMeasureStatusIds' | 'page'
>

export type CheckupResultSendMailParams = {
  targetType: 'ids' | 'conditions'
  ids?: number[]
  conditions?: CheckupResultSendMailConditions
}

export type CheckupResultDocumentParams = {
  branchId?: number
  typeNameId?: number
  clinicId?: number
}

export type CheckupResultClinicsParams = Pick<
  CheckupResultDocumentParams,
  'branchId' | 'typeNameId'
>

export type CheckupFinalResultEmployeesParams = {
  branchIds?: number[]
  departmentIds?: number[]
  finalDecideIds?: number[]
  name?: string | null
  repliedAtStatus?: number
  page?: number
}

export type CheckupFinalResultEmployeeParams = Pick<
  CheckupFinalResultEmployeeDetail,
  'finalDecideId' | 'finalDecideMemo' | 'finalDecidedUserId'
>

type CheckupResultEmployeesData = Readonly<{
  checkupSurveys: CheckupResultEmployee[]
}>

export type CheckupResultEmployeesAllData = Readonly<{
  checkupSurveys: Pick<CheckupResultEmployee, 'id' | 'employmentJudgmentId'>[]
}>

export type CheckupResultEmployeeData = Readonly<{
  checkupSurvey: CheckupResultEmployee
}>

export type CheckupResultEmployeesSendMailData = Readonly<{
  employees: { id: number; name: string }[]
}>

export type CheckupResultParams = Readonly<{
  checkupResult: CheckupResult
}>

export type CheckupResultsData = Readonly<{
  checkupSurveys: CheckupResult[]
}>

export type CheckupResultData = Readonly<{
  result: CheckupResult
}>

export type CheckupResultDocumentData = Readonly<{
  document: CheckupResultDocument
}>

export type CheckupResultClinicsData = Readonly<{
  clinics: Clinic[]
}>

export type CheckupFinalResultEmployeesData = Readonly<{
  checkupSurveys: CheckupFinalResultEmployee[]
}>

export type CheckupFinalResultEmployeesAllData = Readonly<{
  checkupSurveys: { id: number; finalResultId: number }[]
}>

export type CheckupFinalResultEmployeeData = Readonly<{
  checkupSurvey: CheckupFinalResultEmployeeDetail
}>

type CheckupResultEmployeesResponse =
  ApiDataResponse<CheckupResultEmployeesData>
type CheckupResultEmployeeResponse = ApiDataResponse<CheckupResultEmployeeData>
export type CheckupResultEmployeeAllResponse =
  ApiDataResponse<CheckupResultEmployeesAllData>
type CheckupResultEmployeesSendMailResponse =
  ApiDataResponse<CheckupResultEmployeesSendMailData>
type CheckupResultResponse = ApiDataResponse<CheckupResultData>
type CheckupResultsResponse = ApiDataResponse<CheckupResultsData>
type CheckupResultDocumentResponse = ApiDataResponse<CheckupResultDocumentData>
type CheckupResultClinicsResponse = ApiDataResponse<CheckupResultClinicsData>
type CheckupFinalResultEmployeesResponse =
  ApiDataResponse<CheckupFinalResultEmployeesData>
export type CheckupFinalResultEmployeesAllResponse =
  ApiDataResponse<CheckupFinalResultEmployeesAllData>
type CheckupFinalResultEmployeeResponse =
  ApiDataResponse<CheckupFinalResultEmployeeData>

export const getCheckupResultEmployees = (
  fiscalYear: number,
  params?: CheckupResultEmployeesParams,
): Promise<CheckupResultEmployeesResponse> => {
  const url = `${results}/${fiscalYear}/years`
  return api.get(url, params)
}

export const getCheckupResultEmployee = (
  employmentJudgmentId: string,
): Promise<CheckupResultEmployeeResponse> => {
  const url = `${results}/employment_judgments/${employmentJudgmentId}`
  return api.get(url)
}

export const patchCheckupResult = (
  params: CheckupResultParams,
): Promise<CheckupResultResponse> => {
  const url = `${events}/update`
  return api.patch(url, params)
}

export const patchCheckupResultEmployee = (
  employmentJudgmentId: string,
  params: CheckupResultEmployeeParams,
): Promise<CheckupResultEmployeeResponse> => {
  const url = `${results}/employment_judgments/${employmentJudgmentId}`
  return api.patch(url, params)
}

export const postCheckupResultEmployeesSendMail = (
  fiscalYear: number,
  params: CheckupResultSendMailParams,
): Promise<CheckupResultEmployeesSendMailResponse> => {
  const url = `${results}/${fiscalYear}/years/send_mail`
  return api.post(url, params)
}

export const getCheckupResults = (
  employeeId: number,
): Promise<CheckupResultsResponse> => {
  const url = `${results}/employees/${employeeId}`
  return api.get(url)
}

export const getCheckupResultDocument = (
  fiscalYearId: string,
  params?: CheckupResultDocumentParams,
): Promise<CheckupResultDocumentResponse> => {
  const url = `${results}/years/${fiscalYearId}/documents`
  return api.get(url, params)
}

export const getCheckupResultClinics = (
  fiscalYearId: string,
  params?: CheckupResultClinicsParams,
): Promise<CheckupResultClinicsResponse> => {
  const url = `${results}/years/${fiscalYearId}/clinics`
  return api.get(url, params)
}

export const getCheckupFinalResultEmployees = (
  fiscalYearId: number,
  params?: CheckupFinalResultEmployeesParams,
): Promise<CheckupFinalResultEmployeesResponse> => {
  const url = `${finalResults}/years/${fiscalYearId}`
  return api.get(url, params)
}

export const getCheckupFinalResultEmployee = (
  finalResultId: string,
): Promise<CheckupFinalResultEmployeeResponse> => {
  const url = `${finalResults}/${finalResultId}`
  return api.get(url)
}

export const patchCheckupFinalResultEmployee = (
  finalResultId: string,
  params: CheckupFinalResultEmployeeParams,
): Promise<CheckupFinalResultEmployeeResponse> => {
  const url = `${finalResults}/${finalResultId}`
  return api.patch(url, params)
}

export const patchResetAfterMeasure = (
  finalResultId: string,
): Promise<CheckupFinalResultEmployeeResponse> => {
  const url = `/api/v1/checkup/final_results/${finalResultId}/reset_after_measure`
  return api.patch(url)
}
