import { AppDispatch, store } from '../../store'
import {
  DepartmentsData,
  getDepartments,
  DepartmentsParams,
  getDepartmentList,
  postDepartment,
  putDepartment,
  deleteDepartment,
  importDepartmentResults,
  exportResults,
  getStressCheckDepartmentOptions,
} from '../../apis/setting'
import { actions } from '../../modules/setting'
import { enqueueErrorSnackbar, enqueueSnackbar } from '../snackbars'
import {
  checkResponseDataStatus,
  checkResponseStatus,
  downloadFile,
} from '../api'
import { Department } from '../../interfaces/department'
import { CSVValidateRecord } from '../csvUpload'

/**
 * 部署一覧の取得
 *
 * 部署一覧を取得して store に格納する。
 * ただし、すでに一覧が存在する場合には取得しない。
 */
export const fetchDepartments =
  () =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: DepartmentsData; type: string } | void> => {
    const departments = store.getState().setting.departments
    if (departments?.length) {
      return Promise.resolve()
    }

    dispatch(actions.setDepartmentWaiting())
    return getDepartments()
      .then((response) => {
        if (response.status === 'error') {
          throw new Error(response.message)
        } else {
          return response.data
        }
      })
      .then((data) => dispatch(actions.setDepartments(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const fetchDepartmentList =
  (params?: DepartmentsParams) => (dispatch: AppDispatch) => {
    dispatch(actions.setDepartmentWaiting())
    return getDepartmentList(params)
      .then(checkResponseStatus)
      .then((response) => {
        dispatch(actions.setDepartments(response.data))
        dispatch(
          actions.setPaginationDepartments({
            pagination: response.meta ?? null,
          }),
        )
        return {
          branches: response.data.departments,
          pagination: response.meta ?? null,
        }
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const createDepartment =
  (department: Department, callback?: () => void) =>
  (dispatch: AppDispatch) => {
    /* プロパティから id を除去 */
    const params = (({ id: _id, ...rest }) => rest)(department)
    return postDepartment(params)
      .then(checkResponseDataStatus)
      .then(() => {
        dispatch(enqueueSnackbar('部署情報を登録しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

export const updateDepartment =
  (department: Department, callback?: () => void) => (dispatch: AppDispatch) =>
    putDepartment(department)
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('部署情報を更新しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const removeDepartment =
  (departmentId: number, callback?: () => void) => (dispatch: AppDispatch) =>
    deleteDepartment({ id: departmentId })
      .then(checkResponseStatus)
      .then(() => {
        dispatch(enqueueSnackbar('部署情報を削除しました'))
      })
      .then(() => {
        callback && callback()
      })
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })

export const getDepartmentName = (departmentId: number | undefined): string => {
  if (departmentId === undefined) {
    return ''
  }
  const { departments } = store.getState().setting
  return departments?.find((branch) => branch.id === departmentId)?.name ?? ''
}

export const resetDepartments =
  () =>
  (dispatch: AppDispatch): { type: string } => {
    return dispatch(actions.resetDepartments())
  }

export const uploadResults =
  (params: { file: File }, callback?: () => void) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingUploadCsv())
    return importDepartmentResults(params)
      .then((response) => {
        if (response.status === 'error') {
          response.errors &&
            dispatch(
              actions.setErrorResponseResults({ errors: response.errors }),
            )
          throw new Error(response.message)
        } else {
          dispatch(enqueueSnackbar(`${response.message}`))
          return response.message
        }
      })
      .then(() => callback && callback())
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingUploadCsv())
      })
  }

export const downloadResults =
  (fileName?: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(actions.setWaitingDownloadCsv())
    return exportResults()
      .then((response) => downloadFile(response, fileName))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
      .finally(() => {
        dispatch(actions.resetWaitingDownloadCsv())
      })
  }

export const resetErrorResponseResult =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch(actions.resetErrorResponseResults())
  }

export const fetchStressCheckDepartmentOptions =
  (stressCheckId: number | string) =>
  (
    dispatch: AppDispatch,
  ): Promise<{ payload: DepartmentsData; type: string } | void> => {
    dispatch(actions.setDepartmentWaiting())
    return getStressCheckDepartmentOptions(stressCheckId)
      .then((response) => {
        if (response.status === 'error') {
          throw new Error(response.message)
        } else {
          return response.data
        }
      })
      .then((data) => dispatch(actions.setDepartments(data)))
      .catch((reason) => {
        dispatch(enqueueErrorSnackbar(reason))
      })
  }

type EmployeeRecordPick = Pick<Department, 'code' | 'name'>

type ResultRecord = { [P in keyof EmployeeRecordPick]: string }

export const resultsCsvHeaders: CSVValidateRecord<ResultRecord>[] = [
  { index: 0, name: 'code', label: '部署ID' },
  { index: 1, name: 'name', label: '部署名', nullable: true },
]
