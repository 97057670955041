import CloseIcon from '@mui/icons-material/Close'
import { IconButton, styled } from '@mui/material'

type CloseIconButtonProps = {
  className?: string
  onClose: () => void
}

export const CloseIconButton = ({
  className,
  onClose,
}: CloseIconButtonProps) => (
  <StyledIconButton
    className={className}
    aria-label="close"
    onClick={onClose}
    size="large"
  >
    <CloseIcon />
  </StyledIconButton>
)

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}))
