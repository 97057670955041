import { api, apiVersion } from '../../base'
import { FiscalYear } from '../../../interfaces/healthCare/checkup'
import { ApiDataResponse } from '../../../interfaces/api'

const base = `${apiVersion}/checkup/fiscal_years`

export type FiscalYearsData = {
  fiscalYears: FiscalYear[]
}
export type FiscalYearData = {
  fiscalYear: FiscalYear
}
export type FiscalYearParams = Pick<FiscalYear, 'fiscalYear'>

type FiscalYearsResponse = ApiDataResponse<FiscalYearsData>
type FiscalYearResponse = ApiDataResponse<FiscalYearData>

export const getFiscalYears = (): Promise<FiscalYearsResponse> => {
  return api.get(base)
}

export const postFiscalYear = (
  params: FiscalYearParams,
): Promise<FiscalYearResponse> => {
  return api.post(base, params)
}
