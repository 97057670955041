/**
 * メニュー詳細
 */
export const menuDetail = {
  // ダッシュボード
  DASHBOARD: 1,
  // 健康管理
  PANELS_CHECKUP: 2,
  // 過重労働管理
  PANELS_OVERWORK: 3,
  // ストレスチェック
  PANELS_STRESS_CHECK: 4,
  // ウェルネスLite
  PANELS_WELLNESS_LITE: 5,
  // ウェルネスチェック
  PANELS_WELLNESS_CHECK: 6,
  // 復職状態チェック
  PANELS_REINSTATEMENT_CHECK: 7,
  // EL-Naviニュース
  NOTICE: 8,
  // 従業員一覧
  KARTE: 9,
  // メモ
  TABS_MEMO: 10,
  // 面談管理
  TABS_INTERVIEW: 11,
  // 現場報告
  TABS_RECEIVED_REPORT: 12,
  // ウェルネスLite
  TABS_WELLNESS_LITE: 13,
  // 不調者対応ナビ
  TABS_NAVI: 14,
  // 健康管理
  TABS_CHECKUP: 15,
  // 過重労働管理
  TABS_OVERWORK: 16,
  // ストレスチェック
  TABS_STRESS_CHECK: 17,
  // 不調者対応ナビ状況
  NAVI: 18,
  // ウェルネスチェック
  WELLNESS_CHECK: 19,
  // 復職状態チェック
  REINSTATEMENT_CHECK: 20,
  // 面談管理
  INTERVIEW: 21,
  // 人事報告シート
  PERSONNEL_REPORT: 22,
  // 現場報告
  RECEIVED_REPORT: 23,
  // ウェルネスLite
  WELLNESS_LITE: 24,
  // 健康管理
  CHECKUP: 25,
  // 過重労働管理
  OVERWORK: 26,
  // ストレスチェック
  STRESS_CHECK: 27,
  // 基本設定
  SETTING: 28,
  // 会社情報管理
  SETTINGS_COMPANY: 29,
  // 事業所情報管理
  SETTINGS_BRANCH: 30,
  // 部署情報管理
  SETTINGS_DEPARTMENT: 31,
  // 従業員情報管理
  SETTINGS_EMPLOYEE: 32,
  // ユーザー情報管理
  SETTINGS_USER: 33,
} as const
