import { api, apiVersion } from '../base'
import { Company } from '../../interfaces/company'
import { ApiDataResponse } from '../../interfaces/api'

export type CompanyParams = Pick<
  Company,
  | 'name'
  | 'zipcode'
  | 'prefecture'
  | 'address1'
  | 'address2'
  | 'building'
  | 'tel'
>

export type CompanyUpdateParams = CompanyParams & Pick<Company, 'id'>

type CompanyResponse = ApiDataResponse<Company>
const common = `${apiVersion}/settings/companies`

export const patchCompany = (
  params: CompanyUpdateParams,
): Promise<CompanyResponse> => api.patch(`${common}/update`, params)
