import { getDateYear } from './dates'

export const eachYearOfInterval = (
  date: Date | number,
  future = 10,
  past = 10,
): number[] => {
  const baseYear = getDateYear(date)
  const futureYears = [...Array(future)].map((_, index) => baseYear + index)
  const pastYears = [...Array(past)].map((_, index) => baseYear - (index + 1))
  return futureYears.concat(pastYears).sort().reverse()
}

/**
 * チェック値を取得
 *
 * 引数に指定した値を追加/削除した場合に、更新後の配列を返却する。
 * 主に、複数選択チェックボックスの値を取得するために利用。
 *
 * @param value 変更された値
 * @param checked 選択の有無
 * @param values 選択されている項目値
 */
export const getCheckedValues = (
  value: number,
  checked: boolean,
  values: number[],
): number[] => {
  if (checked) {
    return values.includes(value) ? values : [...values, value].sort()
  } else {
    return values.includes(value)
      ? values.filter((val) => val !== value).sort()
      : values
  }
}
