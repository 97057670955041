import * as React from 'react'
import { Button, Typography, Card, CardContent } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    cardCenter: {
      textAlign: 'center',
    },
  }),
)

type Props = {
  qrCode: string
}

export const OtpApp: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      {props.qrCode ? (
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography component="h6" variant="h6">
              アプリをインストール
            </Typography>
            <Typography variant="body2" component="p" color="textSecondary">
              ２段階認証を有効にするには、下記のアプリをインストールする必要がございます。
              <br />
              これらのアプリは、一定時間のみ有効な認証コードを生成する専用のアプリです。
            </Typography>
          </CardContent>
          <div className={classes.cardCenter}>
            <Button
              size="small"
              color="secondary"
              href="https://support.google.com/accounts/answer/1066447?hl=JA&co=GENIE.Platform%3DAndroid&oco=0"
              target="_blank"
              rel="noopener"
            >
              Google Authenticator
            </Button>
            <br />
            <Button
              size="small"
              color="secondary"
              href="https://www.microsoft.com/ja-jp/account/authenticator"
              target="_blank"
              rel="noopener"
            >
              Microsoft Authenticator
            </Button>
          </div>
          <CardContent>
            <Typography component="h6" variant="h6">
              QRコードの読み取り
            </Typography>
            <Typography variant="body2" color="textSecondary">
              アプリを起動し、下記のQRコードを読み取ってください。
              <br />
              ２段階認証に必要な６桁の認証コードが表示されます。
            </Typography>
          </CardContent>
          <div className={classes.cardCenter}>
            <img src={props.qrCode} alt="QRコード" width="128" height="128" />
          </div>
          <br />
        </Card>
      ) : (
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography component="h6" variant="h6">
              認証アプリの起動
            </Typography>
            <Typography variant="body2" component="p" color="textSecondary">
              認証アプリに設定されている6桁の認証コードを表示して下さい。
              <br />
            </Typography>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  )
}
