import {
  ApiDataResponse,
  ApiImportResponse,
  ApiResponse,
} from '../../../interfaces/api'
import { Clinic } from '../../../interfaces/healthCare/checkup'
import { api, apiVersion } from '../../base'

const common = 'checkup/clinics'

export type ClinicsData = {
  clinics: Clinic[]
}
export type ClinicData = {
  clinic: Clinic
}
export type ClinicParams = Pick<Clinic, 'code' | 'name' | 'address'>

type ClinicsResponse = ApiDataResponse<ClinicsData>
type ClinicResponse = ApiDataResponse<ClinicData>

export type ClinicsParams = {
  page?: number
}

export const getClinics = (
  params?: ClinicsParams,
): Promise<ClinicsResponse> => {
  return api.get(`${apiVersion}/${common}`, params)
}

export const getClinic = (clinicId: number): Promise<ClinicResponse> => {
  return api.get(`${apiVersion}/${common}/${clinicId}`)
}

export const postClinic = (params: ClinicParams): Promise<ClinicResponse> => {
  return api.post(`${apiVersion}/${common}`, params)
}

export const patchClinic = (
  clinicId: number,
  params: ClinicParams,
): Promise<ClinicResponse> => {
  return api.patch(`${apiVersion}/${common}/${clinicId}`, params)
}

export const deleteClinic = (clinicId: number): Promise<ApiResponse> => {
  return api.delete(`${apiVersion}/${common}/${clinicId}`)
}

export const importClinics = (params: {
  file: File
}): Promise<ApiImportResponse> => {
  const formData = new FormData()
  formData.append('file', params.file)
  const url = `${apiVersion}/${common}/import`
  return api.multiPart(url, formData)
}

export const exportClinics = (): Promise<Blob> => {
  return api.download(`${apiVersion}/${common}/export`)
}
