import { StoreStatus } from '../../../interfaces/api'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { InterviewWay } from '../../../interfaces/shareNavi/interview'
import { RootState } from '../../../store'
import {
  getInterviewWays,
  InterviewWaysData,
} from '../../../apis/shareNavi/interview'
import { checkResponseDataStatus } from '../../../services/api'

interface State {
  status: StoreStatus
}

const initial: State = {
  status: 'idle',
}

const adapter = createEntityAdapter<InterviewWay>()
const initialState = adapter.getInitialState(initial)

export const fetchInterviewWaysAction = createAsyncThunk<
  InterviewWaysData,
  void,
  { state: RootState }
>(
  'interviewWays/fetchInterviewWays',
  async (_, { rejectWithValue }) => {
    try {
      return await getInterviewWays().then(checkResponseDataStatus)
    } catch (error) {
      return error instanceof Error
        ? rejectWithValue({ message: error.message })
        : rejectWithValue({ message: '面談方法一覧の取得に失敗しました' })
    }
  },
  {
    condition: (_, { getState }) => selectInterviewWaysTotal(getState()) === 0,
  },
)

const interviewWaysSlice = createSlice({
  name: 'interviewWays',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInterviewWaysAction.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchInterviewWaysAction.fulfilled, (state, action) => {
        state.status = 'succeeded'
        adapter.setAll(state, action.payload.ways)
      })
      .addCase(fetchInterviewWaysAction.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const {
  selectAll: selectInterviewWays,
  selectTotal: selectInterviewWaysTotal,
} = adapter.getSelectors((state: RootState) => state.interview.interviewWays)
export const selectInterviewWaysStatus = (state: RootState): State['status'] =>
  state.interview.interviewWays.status

export default interviewWaysSlice.reducer
