import { Notice } from '../interfaces/notice'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Pagination } from '../interfaces/api'
import { NoticesData } from '../apis/notices'

interface State {
  notices: Notice[] | null
  notice: Notice | null
  paginationNotices: Pagination | null
  errorMessage: string | null
  waitingNotices: boolean
}

const initialState: State = {
  notices: null,
  notice: null,
  paginationNotices: null,
  errorMessage: null,
  waitingNotices: false,
}

const notice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setWaitingNotices: (state) => {
      state.waitingNotices = true
    },
    setNotices: (state, actions: PayloadAction<NoticesData>): void => {
      state.notices = actions.payload.notices
      state.waitingNotices = initialState.waitingNotices
    },
    setPaginationNotices: (
      state,
      actions: PayloadAction<{ pagination: Pagination | null }>,
    ): void => {
      state.paginationNotices = actions.payload.pagination
    },
    resetNotices: (state) => {
      state.notices = initialState.notices
      state.paginationNotices = initialState.paginationNotices
    },
    resetNotice: (state) => {
      state.notice = initialState.notice
    },
    resetWaitingNotices: (state) => {
      state.waitingNotices = initialState.waitingNotices
    },
  },
})

export const actions = notice.actions
export default notice.reducer
